import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Accruals } from 'src/app/common/models/accruals';
import { DetailsService } from 'src/app/common/services/details.service';


@Component({
  selector: 'rpc-accrual',
  templateUrl: './accrual.component.html',
  styleUrls: ['./accrual.component.scss'],
})
export class AccrualComponent  implements OnInit, OnDestroy {
  @Input() uNumber!: string;
  accrual!: Accruals;

  siteMap = new Map<string, string>([
    ['ARZ', 'Arizona'],
    ['RST', 'Rochester'],
    ['FLA', 'Florida'],
    ['LASK', 'La Crosse'],
    ['EUMC', 'Eau Claire'],
    ['MAIJ', 'Mankato'],
    ['ALCL',  'Albert Lea'],
    ['AUAC', 'Austin'],
    ['CAMC', 'Cannon Falls'],
    ['LCLC', 'Lake City'],
    ['OWOC', 'Owatonna'],
    ['RWMC', 'Red Wing'],
    ['MPMC', 'Mayo Clinic Square']
  ]);


  private subscription!: Subscription;

  constructor(private detailsService: DetailsService) {}

  ngOnInit(): void {
    this.subscription = this.detailsService
      .getAccruals(this.uNumber)
      .subscribe((res) => (this.accrual = res));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

    /**
   * @param  {string} siteAbrev - site abbrevation to match up with.
   * @returns string - site name for the matching site abbrevation.
   */
    findSiteName(siteAbrev: string): string {
      return this.siteMap.get(siteAbrev) || 'Other';
    }
}

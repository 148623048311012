<div class="side-nav">
    <div class="header">
        <span class="title">
            Saved Filters
        </span>
        <div class="separator"></div>
    </div>
    <div *ngIf="savedFilters.length === 0" class="saved-filters no-filters">
        <span class="filter">You have no saved filters yet.</span>
    </div>
    <div *ngIf="savedFilters.length > 0" class="saved-filters">
        <ng-container *ngFor="let filter of savedFilters; let i = index">
            <!-- Don't show filters without filter names -->
            <ng-container *ngIf="filter.filterName">
                <div class="filter" [@simpleFadeAnimation]="'in'">
                    <div [ngClass]="[filter.show ? 'show' : 'hide']">
                        <span class="filter-name" (click)="handleFilter(filter)">{{filter.filterName}}</span>
                        <fa-icon [icon]="faX" class="delete-icon" (click)="deleteFilter(i, filter)"></fa-icon>
                    </div>
                </div>
                <div class="separator"></div>
            </ng-container>
        </ng-container>
    </div>
</div>
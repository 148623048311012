import { RuntimeConfig } from './runtime-config';

export class AppConfigService {

  static settings: RuntimeConfig;
  static readonly jsonFile: string = 'conf.json';

  constructor() {
  }

  static setConfigs(configs: RuntimeConfig): void {
    AppConfigService.settings = configs;
  }
}

import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from "@angular/material/chips";
import { MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
  MsalBroadcastService, MsalGuard, MsalRedirectComponent
} from '@azure/msal-angular';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpRequestInterceptor } from './common/interceptors/http-request.interceptor';
import { SavedFiltersComponent } from './common/layout/saved-filters/saved-filters.component';
import { MsalApplicationModule } from './msal.application.module';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTreeModule } from '@angular/material/tree';
import { ChipsWithInputComponent } from './common/controls/chips-with-input/chips-with-input.component';
import { PaginatorComponent } from './common/controls/paginator/paginator.component';
import { MessageModalComponent } from './common/dialog/message-modal/message-modal.component';
import { PreviewContentComponent } from './common/layout/preview-content/preview-content.component';
import { PreviewComponent } from './common/layout/preview/preview.component';
import { SideMenuComponent } from './common/layout/side-menu/side-menu.component';
import { SafeHtmlModule } from './common/pipes/sanitize/safe-html.pipe.module';
import { ErrorHttpInterceptor } from './common/interceptors/service-http-error-interceptor.service';
import { SaveFilterDialogComponent } from './dialog/save-filter-dialog/save-filter-dialog.component';
import { HomeComponent } from './home/home.component';
import { ContactsComponent } from './search-results/pi-summary-preview/contacts/contacts.component';
import { EligibilityComponent } from './search-results/pi-summary-preview/eligibility/eligibility.component';
import { PISummaryPreviewComponent } from './search-results/pi-summary-preview/pi-summary-preview.component';
import { ProtocolMaterialsComponent } from './search-results/pi-summary-preview/protocol-materials/protocol-materials.component';
import { DetailContactsComponent } from './search-results/protocol-details/detail-contacts/detail-contacts.component';
import { AccrualComponent } from './search-results/protocol-details/detail/accrual/accrual.component';
import { CategorizationComponent } from './search-results/protocol-details/detail/categorization/categorization.component';
import { DetailComponent } from './search-results/protocol-details/detail/detail.component';
import { PriorityComponent } from './search-results/protocol-details/detail/priority/priority.component';
import { StatusComponent } from './search-results/protocol-details/detail/status/status.component';
import { MoreInfoFundingComponent } from './search-results/protocol-details/more-info-parent/more-info-funding/more-info-funding.component';
import { MoreInfoNotesComponent } from './search-results/protocol-details/more-info-parent/more-info-notes/more-info-notes.component';
import { MoreInfoOtherIdentifiersComponent } from './search-results/protocol-details/more-info-parent/more-info-other-identifiers/more-info-other-identifiers.component';
import { MoreInfoParentComponent } from './search-results/protocol-details/more-info-parent/more-info-parent.component';
import { MoreInfoProtocolDocumentsComponent } from './search-results/protocol-details/more-info-parent/more-info-protocol-documents/more-info-protocol-documents.component';
import { MoreInfoResponsibilitiesComponent } from './search-results/protocol-details/more-info-parent/more-info-responsibilities/more-info-responsibilities.component';
import { ProtocolDetailsComponent } from './search-results/protocol-details/protocol-details.component';
import { SearchResultsPreviewComponent } from './search-results/search-results-preview/search-results-preview.component';
import { NodeTreeComponent } from './common/controls/node-tree/node-tree.component';
import { SearchInputComponent } from './common/controls/search-input/search-input.component';
import { ConfirmationDialogComponent } from './dialog/confirmation-dialog/confirmation-dialog.component';
import { UnhandledErrorComponent } from './unhandled-error/unhandled-error.component';
import { BannerComponent } from './common/layout/banner/banner.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SavedFiltersComponent,
    ChipsWithInputComponent,
    PreviewComponent,
    SideMenuComponent,
    PreviewComponent,
    SearchResultsPreviewComponent,
    PreviewContentComponent,
    PISummaryPreviewComponent,
    EligibilityComponent,
    SaveFilterDialogComponent,
    PaginatorComponent,
    ContactsComponent,
    ProtocolMaterialsComponent,
    ProtocolDetailsComponent,
    DetailComponent,
    StatusComponent,
    DetailContactsComponent,
    AccrualComponent,
    PriorityComponent,
    CategorizationComponent,
    MoreInfoParentComponent,
    MoreInfoProtocolDocumentsComponent,
    MoreInfoOtherIdentifiersComponent,
    MoreInfoFundingComponent,
    MoreInfoResponsibilitiesComponent,
    MoreInfoNotesComponent,
    MessageModalComponent,
    NodeTreeComponent,
    SearchInputComponent,
    ConfirmationDialogComponent,
    UnhandledErrorComponent,
    BannerComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    SafeHtmlModule,
    FormsModule,
    ReactiveFormsModule,
    ReactiveFormsModule,
    ScrollingModule,
    MatTooltipModule,
    MatSidenavModule,
    MatButtonModule,
    MatGridListModule,
    MatChipsModule,
    MatIconModule,
    MatCheckboxModule,
    MatTableModule,
    MatTabsModule,
    MatDialogModule,
    BrowserAnimationsModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatExpansionModule,
    MatTreeModule,
    HttpClientModule,
    MsalApplicationModule.forRoot()
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    MsalBroadcastService,
    MsalGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHttpInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { HomeComponent } from './home/home.component';
import { NotAuthorizedUserGroupComponent } from './not-authorized-user-group/not-authorized-user-group.component';
import { DetailContactsComponent } from './search-results/protocol-details/detail-contacts/detail-contacts.component';
import { DetailComponent } from './search-results/protocol-details/detail/detail.component';
import { MoreInfoParentComponent } from './search-results/protocol-details/more-info-parent/more-info-parent.component';
import { ProtocolDetailsComponent } from './search-results/protocol-details/protocol-details.component';
import { UnhandledErrorComponent } from './unhandled-error/unhandled-error.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'details/:uNumber',
    component: ProtocolDetailsComponent,
    canActivate: [MsalGuard],
    children: [
      {
        path: 'detail',
        component: DetailComponent,
      },
      {
        path: 'contact',
        component: DetailContactsComponent,
      },
      {
        path: 'more-info',
        component: MoreInfoParentComponent,
      }
    ],
  },
  {
    path: 'not-authorized-user-group',
    component: NotAuthorizedUserGroupComponent,
  },
  {
    path: 'unhandled-error',
    component: UnhandledErrorComponent,
  }
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: !isIframe ? 'enabledBlocking' : 'disabled', // Don't perform initial navigation in iframes
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }

<div class="preview-section" id="previewSection"
    [ngClass]="{'is-horizontal-collapsed':isCollapsed , 'is-horizontal-expanded': !isCollapsed  }" [@openCloseContent]="isCollapsed ? 'close' : 'open'">
    <div class="preview-title" (click)='togglePreview()'>
        <div class="preview-subject">
            <span *ngIf="isCollapsed">
                <fa-icon [icon]="caretUp"></fa-icon>
            </span>
            <span *ngIf="!isCollapsed">
                <fa-icon [icon]="caretDown"></fa-icon>
            </span>
            {{getTitle()}}   {{getSubtitle()}}
        </div>
    
    </div>

    <div class="preview-block" id="preview-block">
        <div class="preview-content" id="preview-content">
            <!-- This handles interactions with the preview-content.service for displaying the correct preview component -->
            <rpc-preview-content></rpc-preview-content>
        </div>
    </div>
</div>
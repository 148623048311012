import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faUser, faX } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'rpc-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
  animations: [
    trigger('openCloseMenu', [
      state('open', style({
        width: '80vw'
      })),
      state('close', style({
        width: '0vw'
      })),
      transition('open <=> close', [
        animate('.3s')
      ])
    ])
  ]
})
export class SideMenuComponent implements OnInit {

  @Input() showTabletMenu: boolean = false;
  @Output() showTabletMenuChange: EventEmitter<boolean> = new EventEmitter();

  username: string = '';

  faUser = faUser;
  faX = faX;

  constructor(
    private authService: AuthService) { }

  ngOnInit(): void {
    this.username = this.authService.getUserAccountInfo()?.name ?? '';
  }

  hideSideMenu(): void {
    this.showTabletMenu = false;
    this.showTabletMenuChange.emit(this.showTabletMenu);
  }

  logout(): void {
    this.authService.logout();
  }
}

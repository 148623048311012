import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Contact } from 'src/app/common/models/contact';
import { GroupContact } from 'src/app/common/models/group-contact';
import { Person } from 'src/app/common/models/person';
import { StudyTitle } from 'src/app/common/models/study-title';
import { DetailsService } from 'src/app/common/services/details.service';
import { MatExpansionComponent } from '../mat-expansion.component';

@Component({
  selector: 'rpc-detail-contacts',
  templateUrl: './detail-contacts.component.html',
  styleUrls: ['./detail-contacts.component.scss']
})
export class DetailContactsComponent extends MatExpansionComponent implements OnInit, OnDestroy {

  studyNumber !: string;
  contactsList!: Contact[];
  roleBasedResults: GroupContact[] = [];
  public studyTitle !:StudyTitle;
  private subscriptionsList: Subscription[] = [];

  
  constructor(private route: ActivatedRoute, private detailsService: DetailsService) {
    super();
    this.studyNumber = this.route.parent?.snapshot.params['uNumber'];
    this.studyTitle = this.detailsService.studyTitle;
   }

  ngOnInit(): void {
    this.subscriptionsList.push(
      this.detailsService
        .getStudyContacts(this.studyNumber)
        .subscribe((res) => {
          const roleBasedResults: GroupContact[] = [];

          this.contactsList = res;
          this.contactsList.forEach(cont => {
            const mapValues = this.groupByRole(cont.personsList);
            let groupContact: GroupContact = { label: cont.label, mappedPerson: mapValues, isExpanded:true }
            roleBasedResults.push(groupContact);
          })

          this.roleBasedResults = roleBasedResults;
        })
    );
  }

  groupByRole(personList: Person[]){  
   const map = new Map<string, Person[]>();
    personList.forEach(p => {
      const value = map.get(p.site ? p.role + '(' + p.site + ')' : p.role)
       if(!value) {
        map.set(p.site ? p.role + '(' + p.site + ')' : p.role , [p]);
       }else{
        value.push(p);
       }
    });
    return map;
  }

  /**
   * This is used on keyvalue pipe for perserving order since keyvalue is doing default sorting.
   * 
   * @param a 
   * @param b 
   * @returns 
   */
  asIsOrder(a:any, b:any) {
    return 1;
  }
  
  ngOnDestroy(): void {
    this.subscriptionsList.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

}

import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from 'src/app/app-config/app-config.service';
import { Accruals } from '../models/accruals';
import { Contact } from '../models/contact';
import { EligibilityCriteria } from '../models/eligibility-criteria';
import { Funding } from '../models/funding';
import { Identifier } from '../models/identifier';
import { Notes } from '../models/notes';
import { OrgResponsibility } from '../models/org-responsibility';
import { OtherIdentifier } from '../models/other-identifier';
import { Person } from '../models/person';
import { Priority } from '../models/priority';
import { ProtocolMaterial } from '../models/protocol-material';
import { RpcStudy } from '../models/rpc-study';
import { Status } from '../models/status';
import { StudyTitle } from '../models/study-title';

@Injectable({
  providedIn: 'root',
})
export class DetailsService {

  private _studyTitle!: StudyTitle;

  constructor(private http: HttpClient) { }

  public set studyTitle(studyTitle: StudyTitle) {
    this._studyTitle = studyTitle;
  }

  public get studyTitle() {
    return this._studyTitle;
  }



  public getUserGroup(): Observable<HttpResponse<any>> {
    const api = `${AppConfigService.settings.rpcBackendUriV1}user-group?groupName=DEVICE-RESPROTCATALOG`;
    return this.http.get<any>(
      api, { observe: 'response' });
  }

  /**
  * Get eligibility crieria
  * @param studyNumber 
  * @returns 
  */
  eligibilityCriteria(studyNumber: string): Observable<EligibilityCriteria> {
    return this.http.get<EligibilityCriteria>(`${AppConfigService.settings?.rpcBackendUriV1}studies/${studyNumber}/eligibility-criteria`);
  }

  /**
 * This will get all the contacts for the specified study number.
 * 
 * @param umbrellaNumber 
 * @returns 
 */
  public getStudyContacts(umbrellaNumber: string): Observable<Contact[]> {
    const api = `${AppConfigService.settings?.rpcBackendUriV1}studies/${umbrellaNumber}/contacts`;
    return this.http.get<Contact[]>(api);
  }

  /**
   * Get key contacts
   * @param studyNumber 
   * @returns 
   */
  getContacts(studyNumber: string): Observable<Person[]> {
    return this.http.get<Person[]>(`${AppConfigService.settings?.rpcBackendUriV1}studies/${studyNumber}/key-contacts?roleCodes=role_prin&roleCodes=role_coord`);
  }

  /**
   * Get protocpl documents
   * @param umbrellaNumber 
   * @returns 
   */
  public getProtocolDocuments(
    umbrellaNumber: string
  ): Observable<ProtocolMaterial> {
    const api = `${AppConfigService.settings?.rpcBackendUriV1}studies/${umbrellaNumber}/protocol-material`;
    return this.http.get<ProtocolMaterial>(api);
  }

  /**
   * Get the other identifiers for a passed in velos study number.
   * 
   * @param umbrellaNumber velos umbrella number
   * @returns 
   */
  public getOtherIdentifiers(
    umbrellaNumber: string
  ): Observable<OtherIdentifier[]> {
    const api = `${AppConfigService.settings?.rpcBackendUriV1}studies/${umbrellaNumber}/other-identifiers`;
    return this.http.get<OtherIdentifier[]>(api);
  }

  /**
 * Get the organization responsibilities for a study.
 * 
 * @param umbrellaNumber velos umbrella number
 * @returns 
 */
  public getOrgResponsibilities(
    umberllaNumber: string
  ): Observable<OrgResponsibility> {
    let uNumber = encodeURIComponent(umberllaNumber);
    const api = `${AppConfigService.settings?.rpcBackendUriV1}studies/${uNumber}/org-responsibilities`;
    return this.http.get<OrgResponsibility>(api);
  }

  /**
 * Get the notes on the study.
 * 
 * @param umbrellaNumber velos umbrella number
 * @returns 
 */
  public getNotes(umberllaNumber: string): Observable<Notes[]> {
    let uNumber = encodeURIComponent(umberllaNumber);
    const api = `${AppConfigService.settings?.rpcBackendUriV1}studies/${uNumber}/notes`;
    return this.http.get<Notes[]>(api);
  }

  /**
 * Get study funding for a study.
 * 
 * @param umbrellaNumber velos umbrella number
 * @returns 
 */
  public getStudyFunding(umbrellaNumber: string): Observable<Funding[]> {
    const api = `${AppConfigService.settings?.rpcBackendUriV1}studies/${umbrellaNumber}/funding`;
    return this.http.get<Funding[]>(api);
  }

  /**
   * Get Mayo irb number
   * @param umbrellaNumber 
   * @returns 
   */
  public getMayoIRBNumber(
    umbrellaNumber: string
  ): Observable<Identifier> {
    const api = `${AppConfigService.settings?.rpcBackendUriV1}studies/${umbrellaNumber}/mayo-irb?referenceTypeCode=MAYO_IRB_ID`;
    return this.http.get<Identifier>(api);
  }

  /**
   * 
   * @param umbrellaNumber 
   * @returns 
   */
  public isCancerRelatedStudy(umbrellaNumber: string): Observable<boolean> {
    const api = `${AppConfigService.settings?.rpcBackendUriV1}studies/${umbrellaNumber}/cancer-study`;
    return this.http.get<boolean>(api);
  }

  /**
   * Get protocol material documents
   * @param umberllaNumber 
   * @param source 
   * @param location 
   * @returns 
   */
  public getProtocolMaterialDoc(
    umberllaNumber: string,
    source: string,
    location: string
  ): Observable<Blob> {
    const api = `${AppConfigService.settings?.rpcBackendUriV1}studies/${umberllaNumber}/protocol-material/${source}?location=${location}`;
    return this.http.get(api, {
      responseType: 'blob',
    });
  }

  /**
   * 
   * @param umbrellaNumber 
   * @returns 
   */
  public getParticipationHybridStudyNumber(umbrellaNumber: string): Observable<RpcStudy> {
    const api = `${AppConfigService.settings?.rpcBackendUriV1}studies/${umbrellaNumber}/participation-hybrid-record`;
    return this.http.get<RpcStudy>(api);
  }

  /**
* Get site status
* @param umberllaNumber 
* @returns 
*/
  public getSiteStatus(umberllaNumber: string): Observable<Status> {
    let uNumber = encodeURIComponent(umberllaNumber);
    const api = `${AppConfigService.settings?.rpcBackendUriV1}studies/${uNumber}/sites-status`;
    return this.http.get<Status>(api);
  }

  /**
   * Get Accruals
   * @param umberllaNumber 
   * @returns 
   */
  public getAccruals(umberllaNumber: string): Observable<Accruals> {
    let uNumber = encodeURIComponent(umberllaNumber);
    const api = `${AppConfigService.settings?.rpcBackendUriV1}studies/${uNumber}/accruals`;
    return this.http.get<Accruals>(api);
  }

  /**
   * Get Priority
   * @param umberllaNumber 
   * @returns 
   */
  public getPriority(umberllaNumber: string): Observable<Priority> {
    const api = `${AppConfigService.settings?.rpcBackendUriV1}studies/${umberllaNumber}/priorities`;
    return this.http.get<Priority>(api);
  }

  /**
   * Get Categorization
   * @param umberllaNumber 
   * @returns 
   */
  public getCategorization(umberllaNumber: string): Observable<string[]> {
    const api = `${AppConfigService.settings?.rpcBackendUriV1}studies/${umberllaNumber}/categorization`;
    return this.http.get<string[]>(api);
  }

  public getStudyTitle(umberllaNumber: string): Observable<StudyTitle> {
    let uNumber = encodeURIComponent(umberllaNumber);
    const api = `${AppConfigService.settings?.rpcBackendUriV1}studies/${uNumber}/title`;
    return this.http.get<StudyTitle>(api);
  }

}

import { Component, ComponentRef, EventEmitter, Injectable, Type, ViewContainerRef } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PreviewContentService {

  // Access this ref from your component to create your preview component
  /**
   * Ex:
   * ngAfterViewInit(): void {
        this.searchResultsPreviewComponentRef = this.previewContentService.createComponent(SearchResultsPreviewComponent);
      }
   */
  
  previewContentContainerRef!: ViewContainerRef;
  previewTitle: string = '';
  previewSubtitle: string = '';
  previewEmitter = new EventEmitter<boolean>();

  private _routeToPISummary: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  private _fromPISummary : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() { }

  createComponent<T>(componentType: Type<T>): ComponentRef<T> {
    return this.previewContentContainerRef.createComponent(componentType);
  }

  get routeToPISummary(): Observable<string | null> {
    return this._routeToPISummary.asObservable();
  }

  setRouteToPISummary(protocol: string | null): void {
    this._routeToPISummary.next(protocol);
  }

  setFromPISummary(value : boolean) : void {
    this._fromPISummary.next(value);
  }

  get fromPISummary(): Observable<boolean> {
    return this._fromPISummary.asObservable();
  }


}

<div *ngIf="protocolDocuments; else dataNotFound">
    <mat-tree [dataSource]="nestedDataSource" [treeControl]="nestedTreeControl" class="protocol-tree">
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
            <li class="mat-tree-node">
                <div class="type-icon">
                    <span *ngIf="node.location !== null">
                        <img src="assets/icons/MaterialIconDraft.svg">
                    </span>
                </div>

                <span class="file-text">
                    <div *ngIf="node.location === null; else fileText">
                        {{ capitalizeFirstLetter(node.name) }}
                    </div>
                    <ng-template #fileText>
                        <a (click)="downloadProtocolDocuments(node)">{{ node.name }}</a>
                    </ng-template>

                </span>
            </li>
        </mat-tree-node>

        <mat-nested-tree-node *matTreeNodeDef="let node; let l=level; when: hasNestedChild">
            <li>
                <div class="mat-tree-node">
                    <div class="type-icon">
                    <span *ngIf="node.location === null; else file" class="nested-folder-color">
                        <span *ngIf="l !== 0">
                            <img src="assets/icons/MaterialIconFolder.svg">
                        </span>

                        <span *ngIf="l === 0">
                            <img src="assets/icons/MaterialIconFolderParent.svg">
                        </span>
                        
                    </span>
                    <ng-template #file>
                        <img src="assets/icons/MaterialIconDraft.svg">
                    </ng-template>
                    </div>


                    <span class="file-text">
                        {{ capitalizeFirstLetter(node.name) }}
                    </span>
                    <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
                        <mat-icon class="mat-icon-rtl-mirror">
                            {{
                            nestedTreeControl.isExpanded(node)
                            ? "expand_less"
                            : "expand_more"
                            }}
                        </mat-icon>
                    </button>
                </div>
                <ul [class.protocol-tree-invisible]="!nestedTreeControl.isExpanded(node)">
                    <ng-container matTreeNodeOutlet></ng-container>
                </ul>
            </li>
        </mat-nested-tree-node>
    </mat-tree>
</div>

<ng-template #dataNotFound>
    <span class="font-12px padding-left-2-percent">
        No results found
    </span>
</ng-template>
<div mat-dialog-title class="dialog-title font-normal">{{ data.title }}</div>
<div mat-dialog-content>
    <p>{{ data.content }}</p>
</div>
<div class="dialog-button-left font-normal">
  <div mat-dialog-actions>
    <div mat-dialog-close (click)="onCancel()">Cancel</div>
  </div>
</div>
<div class="dialog-button-right font-normal ">
  <div mat-dialog-actions>
    <div (click)="onDelete()">Delete</div>
  </div>
</div>
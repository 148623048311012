import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { saveAs } from 'file-saver';
import { Observable, of, Subscription } from 'rxjs';
import { AppConfigService } from 'src/app/app-config/app-config.service';
import { PFile } from 'src/app/common/models/pfile';
import { Identifier } from 'src/app/common/models/identifier';
import { ProtocolMaterial } from 'src/app/common/models/protocol-material';
import { RpcStudy } from 'src/app/common/models/rpc-study';
import { DetailsService } from 'src/app/common/services/details.service';

@Component({
  selector: 'rpc-protocol-materials',
  templateUrl: './protocol-materials.component.html',
  styleUrls: ['./protocol-materials.component.scss']
})
export class ProtocolMaterialsComponent implements OnInit , OnDestroy, OnChanges{

  @Input() uNumber: any ="";
  @Input() isKeyPersnl!: boolean;
  @Input() hideTitle!: boolean;

  constentDocuments?: PFile[];
  eligibilityDocuments?: PFile[];
  protFiles?: PFile[];
  ptraxConsentLink?: string;
  isCancerRelatedStudy?:boolean;

  public plmUrl = AppConfigService.settings?.plmUrl;
  public hybridRecord$ !: Observable<RpcStudy>;
  private ptraxServer = AppConfigService.settings?.ptraxServer;
  
  private subscriptionList: Subscription[] = [];

  constructor(private detailsService: DetailsService) {}

  ngOnInit(): void {
    this.hybridRecord$ = this.detailsService.getParticipationHybridStudyNumber(this.uNumber)
    this.subscriptionList.push(
      this.detailsService
        .getProtocolDocuments(this.uNumber)
        .subscribe((res: ProtocolMaterial) => {
          this.constentDocuments = res.consentForms;
          this.eligibilityDocuments = res.protocolFile?.eligibilityForms;
          this.protFiles = res.protocolFile?.protForms;
          /* If consent forms exist, add the prepare consent link */
          if(this.constentDocuments && this.constentDocuments.length > 0){
            this.subscriptionList.push(
              this.detailsService
                .getMayoIRBNumber(this.uNumber)
                .subscribe((res: Identifier) => {
	              if(res != null){
	                 this.ptraxConsentLink = 'https://'+this.ptraxServer+'/ptrax-services/index.html?state=externalNav&target=addParticipant&protocolId='+res.referenceNumber;
                  } 
             })
            );
	      }
        })
    );
    this.detailsService.isCancerRelatedStudy(this.uNumber).subscribe(resp => {
      this.isCancerRelatedStudy = resp;
      if(!this.isCancerRelatedStudy){
        this.eligibilityDocuments = [];
      }
    });
  }

  ngOnChanges():void{
    this.reset();
    this.ngOnInit();
  }

  ngOnDestroy(): void {
    this.subscriptionList.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
  
  reset():void{
    this.hybridRecord$ = of();
    this.constentDocuments=[];
    this.eligibilityDocuments=[];
    this.protFiles =[]
    this.ptraxConsentLink ="";
  }

  
  selectedFile($event: any) {
    console.log($event);
    const node: PFile = $event;
    this.downloadProtocolDocuments(node.source, node.name, node.location);
  }

  /**
   * This will download the file as pdf for the passed in file location.
   *
   * @param  {FileNode} node - contains location of the file.
   */
  downloadProtocolDocuments(
    source: string,
    fileName: string,
    location: string
  ) {
    if (location != null) {
      this.subscriptionList.push(
        this.detailsService
          .getProtocolMaterialDoc(this.uNumber, source, location)
          .subscribe((res) => {
            this.downloadFile(res, fileName);
          })
      );
    }
  }

  /**
   * 
   * @returns Checks if there are checklists
   */
  hasChecklists():boolean{
    return !!this.isCancerRelatedStudy && !!this.eligibilityDocuments ;
  }

  /**
   * This will download the file using filesaver package.
   *
   * @param  {any} response
   * @param  {string} fileName?
   */
  private downloadFile(file: Blob, fileName: string) {
    //Filesaver to save to loal file system.
    saveAs(file, fileName);
  }

}

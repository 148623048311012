<div class="container">
    <rpc-search-input #input [placeholder]="placeholder" [labelName]="labelName"
        [(autocompleteSuggestions)]="autocompleteSuggestions" [requireSuggestion]="requireSuggestion"
        [getSuggestionsFunction]="getSuggestionsFunction" [treeNodes]="treeNodes" [selectedTreeNodes]="selectedTreeNodes"
        [displayRootTreeNode]="displayRootTreeNode" (suggestionSelectionChange)="addSuggestionChip($event)"
        (treeNodesSelectedChange)="handleTreeNodesSelectionChanged($event)" [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
        [shouldUpdateAutoSuggestionOnClick]="shouldUpdateAutoSuggestionOnClick"></rpc-search-input>
    <div class="chip-list">
        <mat-chip-listbox hideSingleSelectionIndicator #chipList aria-label="chip selection" [tabIndex]="-1">
            <mat-chip-option  *ngFor="let chip of chips" (removed)="remove(chip)" class="chip">
                {{chip.value}}
                <button matChipRemove tabindex="-1">
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-chip-option>
        </mat-chip-listbox>
        <ng-container *ngIf="showTreeView()">
            <div class="tree-selections-div" *ngIf="showTreeChips()"
                (click)="displayAllTreeChips = !displayAllTreeChips">
                <mat-chip-listbox hideSingleSelectionIndicator aria-label="chip selection" [tabIndex]="-1">
                <mat-chip-option class="chip-list-content" *ngIf="!displayAllTreeChips" (removed)="removeTreeChip(treeChipString)">{{treeChipString}}
                    <button matChipRemove tabindex="-1" *ngIf="!displayCancelIcon()">
                        <mat-icon>cancel</mat-icon>
                    </button>
                </mat-chip-option>
            </mat-chip-listbox>
                <ng-container *ngIf="displayAllTreeChips">
                    <mat-chip-listbox hideSingleSelectionIndicator aria-label="chip selection" [tabIndex]="-1">
                        <mat-chip-option *ngFor="let chip of treeChips" (removed)="removeTreeChip(chip)" class="chip-list-content">
                            {{chip}}
                            <button matChipRemove tabindex="-1">
                              <mat-icon>cancel</mat-icon>
                            </button>
                        </mat-chip-option>
                    </mat-chip-listbox>
                </ng-container>
            </div>
        </ng-container>
    </div>
</div>

import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { faCaretUp, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { PreviewContentService } from '../../services/preview-content.service';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'rpc-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss'],
  animations: [
    trigger('openCloseContent', [
      state('open', style({
        height: '70vh',
      })),
      state('close', style({
        height: '27vh',
      })),
      transition('open <=> close', [
        animate('.30s')
      ])
    ])
  ]
})
export class PreviewComponent implements OnDestroy {

  isCollapsed: boolean = true;
  caretUp = faCaretUp;
  caretDown = faCaretDown;
  private subscriptions: Subscription[] = [];

  constructor(private previewService: PreviewContentService) {
    this.subscriptions.push(
    this.previewService.previewEmitter.subscribe(res => {
      this.isCollapsed = res;
    })
    );
   }

  togglePreview() {
   this.isCollapsed = !this.isCollapsed;
  }

  getTitle(): string {
    return this.previewService.previewTitle;
  }

  getSubtitle(): string {
    return this.previewService.previewSubtitle;
  }

  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

}

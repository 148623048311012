import { ENTER } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { Node } from '../node-tree/node-tree.service';
import { NodeTreeService } from '../node-tree/node-tree.service';
import { SearchInputComponent } from '../search-input/search-input.component';

export interface Chip {
  id: number;
  value: string;
}

@Component({
  selector: 'rpc-chips-with-input',
  templateUrl: './chips-with-input.component.html',
  styleUrls: ['./chips-with-input.component.scss']
})
export class ChipsWithInputComponent {

  /* For passing through to input component */
  @Input() placeholder: string = "";
  @Input() labelName: string = "";
  @Input() autocompleteSuggestions: string[] = [];
  @Input() requireSuggestion: boolean = false; // Indicates if any chips created must come from suggestions
  @Input() getSuggestionsFunction?: (textValue: string) => Observable<string[]>;
  @Input() treeNodes: Node[] | null = null;
  @Input() selectedTreeNodes: Node[] = [];
  @Input() displayRootTreeNode: boolean = true;
  @Input() shouldUpdateAutoSuggestionOnClick : boolean = false;
  @Output() treeChipsSelectedChange = new EventEmitter<string[]>();
  @Output() treeNodesSelectedChange = new EventEmitter<Node[]>();

  // Chips specific
  @Input() allowDuplicateChips: boolean = false;
  @Input() chips: { id: number, value: string; }[] = [];
  @Output() chipsChange = new EventEmitter<Chip[]>();

  @ViewChild('input', { static: false }) inputChild!: SearchInputComponent;

  displayAllTreeChips: boolean = false;
  treeChips: string[] = [];
  treeChipString: string = '';

  addOnBlur = false;
  readonly separatorKeysCodes: readonly number[] = [ENTER] as const;
  private currentId: number = -1;

  constructor(private nodeTreeService: NodeTreeService) { }

  /**
   * Add chip using the selected option from the autocomplete
   * @param event Autocomplete option selected event
   */
  addSuggestionChip(value: string): void {
    if (this.addChip(value)) {
      this.inputChild.clearValue();
    }
  }

  /**
   * Remove chip
   * @param chip
   */
  remove(chip: Chip): void {
    const index = this.chips.findIndex(x => x.value === chip.value && x.id === chip.id);
    if (index >= 0) {
      this.chips.splice(index, 1);
      this.chipsChange.emit(this.chips);
    }
  }
  /**S
   * Remove chip from the screen and update selectedTreeNodes to reflect.
   * @param removedChip
   */
  removeTreeChip(removedChip: string): void {
    const newSelectedTreeNodes:  Node[]  = [];
    const index = this.treeChips.indexOf(removedChip);
    if (index >= 0) {
      this.treeChips.splice(index, 1);
      this.setTreeChipString();
      this.treeChipsSelectedChange.emit(this.treeChips);
      for (const chipNode of this.treeChips) {
        var tmpNode = this.nodeTreeService.convertDelimitedStringToNode(chipNode, " | ");
        if ( tmpNode !== null ){
          newSelectedTreeNodes.push(tmpNode);
        }
      }
      this.selectedTreeNodes = newSelectedTreeNodes;
    }
  }

  showTreeView(): boolean {
    return !!this.treeNodes;
  }

  showTreeChips(): boolean {
    return this.treeChipString.length > 0;
  }

  /**
   * Emits the selected nodes and updates the display values for the nodes
   * @param nodesSelected selected nodes from the tree component
   */
  handleTreeNodesSelectionChanged(nodesSelected: Node[]): void {
    this.updateTreeChips(nodesSelected);
  }

  /**
   * Updates the display for the selected tree values
   * @param nodes selected nodes to be displayed
   */
  updateTreeChips(nodes: Node[]): void {
    this.treeChips = [];
    for (const n of nodes) {
      this.treeChips.push(...this.nodeTreeService.convertNodeToDelimitedStrings(n, ' | ', this.displayRootTreeNode));
    }
    this.treeChipsSelectedChange.emit(this.treeChips);

    // The tree chip string needs to be updated, but if it's updated immediately, we violate the Angular lifecycle hooks
    // https://angular.io/guide/lifecycle-hooks#wait-before-updating-the-view
    setTimeout(() => {
      this.setTreeChipString();
    }, 50);
  }

  setSelectedTreeNodes(treeStrings: string[]): void {
    this.treeChips = treeStrings;
  }

  displayCancelIcon(): boolean {
    return this.treeChips.length > 1;
  }

  /**
  * Shared functionality to add a value to the chip list
  * @param value Value to add
  * @returns if the value was added as a chip
  */
  private addChip(value: string): boolean {
    if (this.shouldChipBeAdded(value)) {
      this.chips.push({
        id: this.currentId--,
        value: value
      });
      this.chipsChange.emit(this.chips);
      this.inputChild.clearValue();
      return true;
    }
    return false;
  }

  private shouldChipBeAdded(chipValue: string): boolean {
    return !!chipValue
      && (this.allowDuplicateChips || !this.chips.find(c => c.value === chipValue))
      && (!this.requireSuggestion || this.autocompleteSuggestions.includes(chipValue));
  }

  private setTreeChipString(): void {
    if (this.treeChips.length === 0) {
      this.treeChipString = '';
    }
    else {
      this.treeChipString = this.treeChips[0];
      if (this.treeChips.length > 1) {
        this.treeChipString += `...(+${this.treeChips.length - 1} more)`;
      }
    }
  }

 
}

<div class="side-menu" [@openCloseMenu]="showTabletMenu ? 'open': 'close'">
    <div class="side-content">
        <div class="close-icon-div">
            <fa-icon [icon]="faX" size="2x" (click)="hideSideMenu()"></fa-icon>
        </div>
        <div class="saved-filters">
            <rpc-saved-filters (hideSideMenu)="hideSideMenu()"></rpc-saved-filters>
        </div>
        <div class="footer">
            <span class="username">{{username}}</span>
            <div class="logout" (click)="logout()">
                <fa-icon [icon]="faUser" class="user-icon"></fa-icon>
                <span>Logout</span>
            </div>
        </div>
    </div>
</div>
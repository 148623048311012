import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Notes } from 'src/app/common/models/notes';
import { DetailsService } from 'src/app/common/services/details.service';

@Component({
  selector: 'rpc-notes',
  templateUrl: './more-info-notes.component.html',
  styleUrls: ['./more-info-notes.component.scss']
})
export class MoreInfoNotesComponent implements OnInit, OnDestroy {

  @Input()
  uNumber!: string;

  notesList: Notes[] = [];
  private subscriptionList: Subscription[] = [];

  constructor(private detailsService: DetailsService) {}

  ngOnDestroy(): void {
    this.subscriptionList.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  ngOnInit(): void {
    this.subscriptionList.push(
    this.detailsService.getNotes(this.uNumber).subscribe((res) => {
      this.notesList = res;
      this.notesList.sort((a, b) => {
        if (a.sequenceNumber > b.sequenceNumber) return 1;
        if (a.sequenceNumber < b.sequenceNumber) return -1;
        return 0;
      });
    })
    );
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Funding } from 'src/app/common/models/funding';
import { DetailsService } from 'src/app/common/services/details.service';

@Component({
  selector: 'rpc-funding',
  templateUrl: './more-info-funding.component.html',
  styleUrls: ['./more-info-funding.component.scss']
})
export class MoreInfoFundingComponent implements OnInit {

  @Input()
  uNumber!: string;
  funding$!: Observable<Funding[]>;


  constructor(
    private detailsService: DetailsService
  ) {
  }


  ngOnInit(): void {
    this.funding$ = this.detailsService.getStudyFunding(
      this.uNumber
    );
  }

}

import { Component, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { ActivatedRoute } from '@angular/router';
import { SectionType } from 'src/app/common/models/section-type';
import { MatExpansionComponent } from '../mat-expansion.component';

@Component({
  selector: 'rpc-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
})
export class DetailComponent extends MatExpansionComponent {
  umbrellaNumber!: string;
  sections: SectionType[] = [
    {
      id: 1,
      title: 'Status',
      description: 'Status of the study',
      isExpanded:true
    },
    {
      id: 2,
      title: 'Accrual',
      description: 'Accruals on the study', 
      isExpanded:true
    },
    {
      id: 3,
      title: 'Protocol Documents',
      description: 'Protocol documents on the study',
      isExpanded:true
    },

    {
      id: 4,
      title: 'Priority',
      description: 'Priority of the study',
      isExpanded:true
    },

    {
      id: 5,
      title: 'Categorization',
      description: 'Categorization of the study',
      isExpanded:true
    },
  ];


  constructor(public activatedRoute: ActivatedRoute ) {
    super();
    this.umbrellaNumber = this.activatedRoute.snapshot.parent?.params['uNumber'];
  }

}

<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
        <button mat-icon-button disabled></button>
        <mat-checkbox color='primary' class="checklist-leaf-node" [checked]="checklistSelection.isSelected(node)"
            (change)="toggleLeafItemSelected(node)">{{node.item}}</mat-checkbox>
    </mat-tree-node>
    <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
        <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.item">
            <mat-icon class="mat-icon-rtl-mirror">
                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
        </button>
        <mat-checkbox color='primary' [checked]="descendantsAllSelected(node)" [indeterminate]="descendantsPartiallySelected(node)"
            (change)="toggleItemsSelected(node)">
            {{node.item}}</mat-checkbox>
    </mat-tree-node>
</mat-tree>

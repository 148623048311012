<div class="protocol-details" *ngIf="study">
  <a class="back-to-results-link" *ngIf="!isDetailsBookMarked" (click)="backToSearchResults()"> <span> <fa-icon
        [icon]="faAngleLeft"></fa-icon>Back
      to Protocol Results</span> </a>
  <div class="protocol-number">
    <span title="Protocol Number">{{study.protocol}}</span><span *ngIf="study.mayoIrbNumber" title="Mayo IRB Number">&nbsp;/&nbsp;{{study.mayoIrbNumber}}</span>
  </div>
  <span class="protocol-title">
    {{study.title}}
  </span>
    <div class="back-to-pi-summary-div" *ngIf="!isDetailsBookMarked && isFromPISummary">
      <span class="back-to-pi-summary" (click)="backToPISummary()"> View PI Summary </span>
    </div>
  <div class="details-tab-panel">
    <nav mat-tab-nav-bar mat-stretch-tabs mat-align-tabs="start" [tabPanel]="tabPanel">
      <a mat-tab-link *ngFor="let tabItem of tabs" [routerLink]="[tabItem.route]" routerLinkActive
        #rla="routerLinkActive" [active]="rla.isActive">
        {{ tabItem.label }}
      </a>
    </nav>
    <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
    <div class="tab-body-content">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Person } from 'src/app/common/models/person';
import { DetailsService } from 'src/app/common/services/details.service';
import { SearchService } from 'src/app/common/services/search.service';
import { SearchRequest } from './../../../common/models/search-request';

@Component({
  selector: 'rpc-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss'],
})
export class ContactsComponent implements OnInit {
  @Input() studyNumber: any = '';
  @Input() protocolNumber: any = '';
  @Input() title: any = '';
  emailSubject: string = '';
  searchRequest!: SearchRequest | null;

  persons: Person[] = [];

  private subscriptions: Subscription[] = [];

  constructor(
    private detailsService: DetailsService,
    private searchService: SearchService
  ) {
    this.subscriptions.push(
      this.searchService.lastSearchRequest.subscribe((res) => {
        this.searchRequest = res;
      })
    );
  }

  ngOnInit(): void {
    this.getContacts();
    this.emailSubject = this.getEmailSubject(this.protocolNumber, this.title);
  }

  /**
   * Get Key contacts
   */
  getContacts() {
    const filteredContactList: Person[] = [];
    this.resetContacts();
    this.subscriptions.push(
      this.detailsService
        .getContacts(this.studyNumber)
        .subscribe((result: Person[]) => {
          if (this.searchRequest && this.searchRequest?.location.length > 0) {
            const selectedLocations = this.searchRequest.location;
            //Only display selected location based contacts.
            if (result != null && result.length > 0) {
              result.forEach((contact) => {
                if (selectedLocations.includes(contact.locationGroup)) {
                  filteredContactList.push(contact);
                }
              });
              this.persons = filteredContactList;
            }
          } else {
            this.persons = result;
          }
        })
    );
  }

  /**
   * Resets contacts
   */
  resetContacts() {
    this.persons = [];
  }

  getEmailSubject(protocolNumber: any, title: any): string {
    let subject = '';
    if (protocolNumber && title) {
      subject = protocolNumber + ' : ' + title;
    } else {
      //so either the protol number or title exists, not both
      subject = (protocolNumber ? protocolNumber : '') + (title ? title : '');
    }

    //email subject can only be so long... we will truncate  and add eclipses
    if (subject.length > 50) {
      subject = subject.slice(0, 50) + '...';
    }

    return subject;
  }

}

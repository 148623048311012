import {
  HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, Observable, tap, throwError } from 'rxjs';
import { LoadingService } from '../services/loading.service';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

  constructor(
    private loadingService: LoadingService
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const url = request.url;

    // URL Validation to prevent untrusted calls
    if (!this.isURLValid(request.urlWithParams)) {
      return throwError(() => new Error(`Call failed to invalid URL: ${request.urlWithParams}`));
    }

    if (!(url.includes('query_suggestion') || url.includes('search'))) {
      this.loadingService.setLoading(true, url);
      return next.handle(request)
        .pipe(
          tap(event => {
            if (event instanceof HttpResponse) {
              this.loadingService.setLoading(false, url);
            }
            return event;
          }),
          catchError(
            err => {
              this.loadingService.setLoading(false, url);
              return throwError(() => new Error(err));
            }
          )
        );
    }
    return next.handle(request);
  }

  /**
   * Checks if the given URL is valid and if it's going to a mayo URL or localhost
   * @param url URL to be checked 
   * @returns Whether or not it's a valid URL
   */
  isURLValid(url: string): boolean {
    // https://stackoverflow.com/a/1547940 - with some modifications
    const VALID_CHARACTERS_REGEX = /^[a-zA-Z0-9\s\.\/\?\~:\-\_\;\\#\[\]\@\!\$&\'\|\(\)\*\+\,\;\=%]+$/;
    // https://stackoverflow.com/a/42619368 - with some modifications

    const URL_REGEX: RegExp = /^(https?:\/\/)?(www.)?((localhost:\d+\/)|(\/?\.?[a-zA-Z0-9\s\~:\-\_\;\\#\[\]\@\!\$&\'\|\(\)\*\+\,\;\=\.\/%]+\/?\??))*$/;

    const EXCEPTIONS: string[] = ['conf.json'];
    const ALLOWED_URLS: string[] = ['mayo.edu', 'localhost:'];

    if (
      (!VALID_CHARACTERS_REGEX.test(url) // Make sure characters are valid, so that we should avoid catastrophic backtracking 
        || !URL_REGEX.test(url)
        || (!ALLOWED_URLS.find(au => url.includes(au))
          && !url.startsWith('/') // indicates it's a relative path
        )
      )
      && !EXCEPTIONS.includes(url)) {
      return false;
    }
    return true;
  }
}

import { Injectable } from '@angular/core';

export interface Node {
  children?: Node[];
  item: string;
}

export interface FlatNode {
  item: string;
  level: number;
  expandable: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class NodeTreeService {

  constructor() { }


  sortNode(prev: Node, current: Node): number { return prev.item > current.item ? 1 : -1; }

  /**
   * Splits the given string and builds a node tree starting from the first value to the last value
   * @param delimitedString String that has potentially multiple values delimited by some string
   * @param delimiter The string that separates the sub-values within the delimited string
   * @returns Node tree represented by each of the sub-values
   */
  convertDelimitedStringToNode(delimitedString: string, delimiter: string): Node | null {
    let result: Node | null = null;
    if (delimitedString?.length > 0) {
      const buildTree =
        (stringArray: string[]): Node => {
          const result: Node = {
            item: stringArray[0]
          };
          if (stringArray.length > 1) {
            result.children = [buildTree(stringArray.slice(1))];
          }
          return result;
        };

      if (delimiter) {
        const splitString = delimitedString.split(delimiter);
        result = buildTree(splitString);
      }
      else { // if we don't get a delimiter or the delimiter is an empty string
        result = {
          item: delimitedString
        };
      }
    }

    return result;
  }

  /**
   * Converts the given Node and descendants to a list of strings with each node in the tree separated by the delimiter
   * @param node Node to convert
   * @param delimiter String that should separate each Node.item value in the tree
   * @param displayRootTreeNode Should the root node be included
   * @returns List of the node's descendants' item values delimited by the delimiter
   */
  convertNodeToDelimitedStrings(node: Node, delimiter: string, displayRootTreeNode?: boolean): string[] {
    if (displayRootTreeNode === undefined) displayRootTreeNode = true;

    const result: string[] = [];

    const getTreeChipDisplays = (node: Node, currentString: string): string[] => {
      const result: string[] = [];
      if (currentString.length > 0) {
        currentString += delimiter;
      }
      currentString += node.item;
      if (node.children && node.children?.length > 0) {
        for (const child of node.children) {
          result.push(...getTreeChipDisplays(child, currentString));
        }
      }
      else {
        result.push(currentString);
      }
      return result;
    };

    let startingString = '';
    if (displayRootTreeNode) {
      startingString = node.item;
    }
    if (node.children) {
      for (const child of node.children) {
        result.push(...getTreeChipDisplays(child, startingString));
      }
    }

    return result;
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { OtherIdentifier } from 'src/app/common/models/other-identifier';
import { DetailsService } from 'src/app/common/services/details.service';

@Component({
  selector: 'rpc-other-identifiers',
  templateUrl: './more-info-other-identifiers.component.html',
  styleUrls: ['./more-info-other-identifiers.component.scss'],
})
export class MoreInfoOtherIdentifiersComponent implements OnInit {
  @Input()
  uNumber!: string;
  otherIdentifiers$!: Observable<OtherIdentifier[]>;

  constructor(private detailsService: DetailsService) {}

  ngOnInit(): void {
    this.otherIdentifiers$ = this.detailsService.getOtherIdentifiers(
      this.uNumber
    );
  }
}

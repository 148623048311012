import {  Directive, ViewChild } from "@angular/core";
import { MatAccordion } from "@angular/material/expansion";

@Directive()
export class MatExpansionComponent {
    @ViewChild(MatAccordion) accordion!: MatAccordion;
    isExpanded = true;

    toggleSection() {
        this.isExpanded = !this.isExpanded;
        if (this.isExpanded) {
            this.accordion.openAll();
        } else {
            this.accordion.closeAll();
        }
    }

    /**
     * Checks if all sections/panels are expanded
     * @returns 
     */
    public isAllExpanded(section: any[]) {
        if (section?.filter(x => x.isExpanded === true)?.length === section?.length) {
            this.isExpanded = true;
        } else {
            this.isExpanded = false;
        }
        return this.isExpanded;
    }

}
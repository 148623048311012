import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, of, tap } from 'rxjs';
import { MessageModalComponent } from './message-modal.component';

@Injectable({
  providedIn: 'root'
})
export class MessageModalService {

  private _messageModal: MatDialog | null = null;

  constructor(private messageModal: MatDialog) { }

  openMessageModal(options: { title: string, message: string, containsHTML?: boolean; }): Observable<null> {
    if (!this._messageModal) {
      this._messageModal = this.messageModal;

      const modal = this._messageModal.open(MessageModalComponent,
        {
          data: options,
          hasBackdrop: true,
          autoFocus: false,
          backdropClass: 'modal-backdrop'
        });

      return modal.afterClosed().pipe(tap(result => {
        this._messageModal = null;
      }));
    }

    return of();
  }

}

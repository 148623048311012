import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { faDisplay } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { EligibilityCriteria } from 'src/app/common/models/eligibility-criteria';
import { DetailsService } from 'src/app/common/services/details.service';
import { SearchService } from 'src/app/common/services/search.service';

@Component({
  selector: 'rpc-eligibility',
  templateUrl: './eligibility.component.html',
  styleUrls: ['./eligibility.component.scss']
})
export class EligibilityComponent implements OnInit, OnChanges {

  @Input() studyNumber: any = "";

  eligibilityCriteria: EligibilityCriteria = { data: '' }

  private subscriptions: Subscription[] = [];

  constructor(private detailsService: DetailsService) { }

  ngOnInit(): void {
    this.getEligibilityCriteria();
  }

  ngOnChanges():void{
    this.getEligibilityCriteria();
  }

  /**
   * Get eligibility criteria
   */
  getEligibilityCriteria(){
    this.resetEligibilityCriteria();
    this.subscriptions.push(
      this.detailsService.eligibilityCriteria(this.studyNumber).subscribe((result) => {
        this.eligibilityCriteria = result;
      })
    );

  }

  /**
     * Resets Eligibility Criteria
     */
  resetEligibilityCriteria(){
    this.eligibilityCriteria = { data: '' };
  }
}
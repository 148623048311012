<div class="details">

  <div class="toggle-div">
    <a (click)="toggleSection()">
      <span *ngIf="!isAllExpanded(roleBasedResults)"> Open all</span>
      <span *ngIf="isAllExpanded(roleBasedResults)"> Collapse all</span>
    </a>
  </div>


  <mat-accordion class="tab-headers-align" [togglePosition]="'after'" multi="true">
    <ng-container *ngFor="let contact of roleBasedResults">
      <mat-expansion-panel [(expanded)]="contact.isExpanded">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span class="contact-title">
              {{ contact.label }}
            </span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container *ngFor="let mapped of contact.mappedPerson | keyvalue: asIsOrder">
          <div class="contact-role">
            <span class="role">
              {{mapped.key}}
            </span>
            <table class="other-detail">
              <tr *ngFor="let person of mapped.value">
                <td>
                  <span class="username">
                    <a *ngIf="person.email"
                      href="mailto:{{ person.email }} ?subject={{studyTitle.protocol}} : {{studyTitle.title}}">
                      {{ person.name
                      }}</a>
                    <a *ngIf="person.email === undefined"> {{ person.name }}</a>
                  </span>
                </td>
                <td>
                  <span class="phone">
                    {{ person.phone }}
                  </span>
                </td>
              </tr>
            </table>
          </div>

        </ng-container>

      </mat-expansion-panel>
    </ng-container>
  </mat-accordion>

</div>
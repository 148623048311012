<div class="navigation-div">
  <div class="back-div" (click)="handleBackClicked()">
    <fa-icon [icon]="faAngleLeft"></fa-icon>
    <span>Back</span>
  </div>
</div>
<p class="protocol-number"><span title="Protocol Number">{{study?.protocol_number}}</span><span *ngIf="study?.mayo_irb_number" title="Mayo IRB Number">&nbsp;/&nbsp;{{study?.mayo_irb_number}}</span></p>
<p class="study-title">{{study?.study_title}}</p>
<div class="view-protocol-div">
  <span class="view-protocol" (click)="redirectToViewProtocolPage()">View Protocol Page</span>
</div>
<div class="content">
  <mat-tab-group fitInkBarToContent>
    <mat-tab label="Eligibility">
      <rpc-eligibility [studyNumber]="study?.id"></rpc-eligibility>
    </mat-tab>
    <mat-tab label="Contacts">
      <rpc-contacts [studyNumber]="study?.id" [protocolNumber]="study?.protocol_number"
                    [title]="study?.study_title"></rpc-contacts>
    </mat-tab>
    <mat-tab label="Forms">
      <rpc-protocol-materials [uNumber]="study?.id"></rpc-protocol-materials>
    </mat-tab>
  </mat-tab-group>
</div>
<div class="footer">
  <div class="previous-next-div">
    <span class="previous-div" (click)="handlePreviousClicked()" *ngIf="showPrevious">
      <fa-icon [icon]="faAngleLeft"></fa-icon>Previous
    </span>
    <span class="previous-next-delimiter" *ngIf="showPrevious && showNext">&nbsp;|&nbsp;</span>
    <span class="next-div" (click)="handleNextClicked()" *ngIf="showNext">
      Next<fa-icon [icon]="faAngleRight"></fa-icon>
    </span>
  </div>
</div>

import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { DetailsService } from 'src/app/common/services/details.service';

@Component({
  selector: 'rpc-categorization',
  templateUrl: './categorization.component.html',
  styleUrls: ['./categorization.component.scss'],
})
export class CategorizationComponent implements OnInit,OnDestroy
{
  @Input()
  public uNumber!: string;
 
  categorization!: string[];
  private subscription!: Subscription;
  
  constructor(private detailService: DetailsService) {  }

  ngOnInit(): void {
    this.subscription = this.detailService
      .getCategorization(this.uNumber)
      .subscribe((res:any) => (this.categorization = res));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}

<div class="rpc-container">
  <div *ngIf="(loading$ | async) === true" class="loading">
    <mat-spinner [diameter]="80"></mat-spinner>
  </div>

  <rpc-banner *ngIf="displayBanner()"></rpc-banner>

  <div [className]="'side-nav' + (displayBanner()? ' banner-displayed': '')">
    <!-- Logo from https://intranet.mayo.edu/charlie/brand-resource-center/logomarks/ -->
    <img class="mayo-logo" (click)="navigateToHome()"
      src="assets/RGB_MayoClinic_PrimaryLogomark_LargeFormat_White.png" />
    <div class="hamburger-menu-div">
      <span class="hamburger-menu" size="4x" (click)="handleHamburgerIconClicked()">
        <img src="assets/icons/MaterialIconMenu.svg">
      </span>

    </div>
    <rpc-side-menu [(showTabletMenu)]="showTabletSideMenu"></rpc-side-menu>
  </div>
  <!-- cdkScrollable locks overlays when this portion of the page is scrolled -->
  <div class="main-content" cdkScrollable>
    <mat-toolbar>
      <div class="header-welcome">
        <span class="welcome-message" *ngIf="displayWelcome">
          Welcome,{{ displayName }}!
        </span>
        <span id="rpcTitle" class="rpc-title-without-welcome" *ngIf="!displayWelcome" (click)="navigateToHome()">
          Research Protocol Catalog
        </span>
        <div class="profile-login">
          <span class="user-info" *ngIf="loginDisplay && !displayWelcome">
            {{ displayName }}
          </span>
          <button class="log-out-button" mat-raised-button *ngIf="loginDisplay" (click)="logout()">
            Logout
          </button>
        </div>
      </div>
      <div class="research-protocol-Catalog-title" *ngIf="displayWelcome">
        Research Protocol Catalog
      </div>
    </mat-toolbar>
    <div class="container" *ngIf="!isLoading" [ngClass]="displayWelcome ? 'preview-pane-buffer' : '' ">
      <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
      <router-outlet *ngIf="!isIframe"></router-outlet>
    </div>
  </div>
  <rpc-preview [hidden]="!displayWelcome">
    <!-- See rpc-preview-content for adding preview components -->
  </rpc-preview>
</div>
<div id="appVersion" style="display: none;">{{appVersion}}</div>
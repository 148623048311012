import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Status } from 'src/app/common/models/status';
import { StudySiteStatus } from 'src/app/common/models/study-site-status';
import { DetailsService } from 'src/app/common/services/details.service';

@Component({
  selector: 'rpc-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
})
export class StatusComponent implements OnInit, OnDestroy {
  @Input() uNumber!: string;

  siteStatus: Status = {
    leadSite: '',
    studySites: [],
  };
  leadSite!: string;

  siteMap = new Map<string, string>([
    ['Rochester', 'RST'],
    ['Arizona', 'ARZ'],
    ['Florida', 'FLA'],
    ['MCHS-Eau Claire', 'EUMC'],
    ['MCHS-Mankato', 'MAIJ'],
    ['Mayo Clinic Square', 'MPMC'],
  ]);

  private subscription!: Subscription;

  constructor(private detailsService: DetailsService) {}

  ngOnInit(): void {
    this.subscription = this.detailsService
      .getSiteStatus(this.uNumber)
      .subscribe((res: any) => {
        this.siteStatus = res;
        this.leadSite = this.getLeadSiteCode(res.leadSite);
        this.parseSiteStatusDate(this.siteStatus.studySites);
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  /**
   *  parse the list of sites, to find out the site name and color mapping for site and status.
   *
   * @param  {Status[]} site - list of sites to parse.
   */
  parseSiteStatusDate(site: StudySiteStatus[]) {
    site.forEach((s) => {
      s.formatedAvailDate = new Date(s.availDate.replace(/-/g, '/'));
    });
  }

  /**
   * Get site code by site name
   * @param leadSiteName
   * @returns
   */
  getLeadSiteCode(leadSiteName: string) {
    return this.siteMap.get(leadSiteName) || '';
  }

  isLeadSite(site: string): boolean {
    let isLeadSite = false;
    if (site === this.leadSite) {
      isLeadSite = true;
    }
    return isLeadSite;
  }

  /**
   * @param  {string} statusType - statusType which indicates status of the study.
   * @returns string - css classname which refers in the component scss class.
   */
  mapStatusToColor(statusType: string): string {
    let cssClassName = '';
    switch (statusType) {
      case 'Assessment':
      case 'Start-Up':
      case 'Development':
      case 'Closure':
      case 'Completed':
        cssClassName = 'site-status-black';
        break;
      case 'Conduct':
      case 'Accruing':
        cssClassName = 'site-status-green';
        break;
      case 'Temporarily Closed':
      case 'Accrual Closed':
        cssClassName = 'site-status-red';
        break;
    }
    return cssClassName;
  }
}

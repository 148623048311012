import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'rpc-message-modal',
  templateUrl: './message-modal.component.html',
  styleUrls: ['./message-modal.component.scss']
})
export class MessageModalComponent {
  title: string = 'Alert';
  message: string = '';
  containsHTML: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) dialogData: any,
    public activeModal: MatDialogRef<MessageModalComponent>) {
    this.title = dialogData.title;
    this.message = dialogData.message;
    this.containsHTML = dialogData.containsHTML;
  }

  okClicked(): void {
    this.activeModal.close();
  }

}

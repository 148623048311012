import { Observable } from 'rxjs';
import { Component, Input, OnInit } from '@angular/core';
import { Priority } from 'src/app/common/models/priority';
import { DetailsService } from 'src/app/common/services/details.service';


@Component({
  selector: 'rpc-priority',
  templateUrl: './priority.component.html',
  styleUrls: ['./priority.component.scss']
})
export class PriorityComponent implements OnInit {

  @Input()
  public uNumber!: string;
  public $priority!: Observable<Priority>;

  constructor(private detailService: DetailsService) { }

  ngOnInit(): void {
    this.$priority = this.detailService.getPriority(this.uNumber);
  }

}

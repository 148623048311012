import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppConfigService } from 'src/app/app-config/app-config.service';
import { DiseaseGroupCategory } from '../models/disease-group-category';
import { QuerySuggestionResult } from '../models/query-suggestion-result';
import { SearchRequest } from '../models/search-request';
import { SearchResponse } from '../models/search-response';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  // The expectation, is that the first time the app is loaded, the first search value is null
  private _lastSearchRequest: BehaviorSubject<SearchRequest | null> = new BehaviorSubject<SearchRequest | null>(null);
  get lastSearchRequest(): Observable<SearchRequest | null> {
    return this._lastSearchRequest.asObservable();
  }

  constructor(private http: HttpClient) { }

  /**
   * Sends the search parameters to the backend to perform a search on the protocols
   * Note: The search results could be cached similarly if we need to
   * @param searchRequest Object containing the search parameters
   * @returns Result of the Backend (Elastic) search
   */
  searchProtocols(searchRequest: SearchRequest): Observable<SearchResponse> {
    this._lastSearchRequest.next(searchRequest);
    return this.http.post<SearchResponse>(`${this.getSearchURLV3()}search.json`, searchRequest);
  }

  /**
   * Gets possible suggestions for the given field with the search text as the base
   * @param field UI field that's requesting suggestions - make sure to check backend for options
   * @param searchText Text from the UI field that's the basis for suggestions
   * @returns List of possible suggestions
   */
  getSearchSuggestions(field?: string, searchText?: string): Observable<QuerySuggestionResult> {
    let url = `${this.getSearchURLV3()}query_suggestion.json`;
    if (field && searchText) {
      url += `?${field}=${searchText}`;
    }
    return this.http.get<QuerySuggestionResult>(url);
  }

  /**
   * Clears the last search request
   * Used to force the home page to load the selected saved filter
   */
  resetLastSearchRequest(): void {
    this._lastSearchRequest.next(null);
  }

  setLastSearchRequest(searchRequest: SearchRequest | null): void {
    this._lastSearchRequest.next(searchRequest);
  }

  /**
   * Gets the sub-disease group categories of the given disease group
   * @param diseaseGroup Selected disease group that's the root of our disease group categories
   * @returns The sub categories for the given disease group
   */
  getDiseaseGroupCategories(diseaseGroup: string): Observable<DiseaseGroupCategory[]> {
    return this.http.get<DiseaseGroupCategory[]>(AppConfigService.settings?.rpcBackendUriV3 + 'disease-group-categorization/disease-categories?diseaseGroup=' + encodeURIComponent(diseaseGroup));
  }

  private getSearchURLV2(): string {
    return AppConfigService.settings?.rpcBackendElasticSearchUriV2 + 'engines/rpc-int/';
  }

  private getSearchURLV3(): string {
    return AppConfigService.settings?.rpcBackendElasticSearchUriV3 + 'engines/rpc-int/';
  }

}

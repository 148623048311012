<div class="details" *ngIf="umbrellaNumber">
  <div class="toggle-div">
    <a (click)="toggleSection()">
      <span *ngIf="!isAllExpanded(sections)"> Open all</span>
      <span *ngIf="isAllExpanded(sections)"> Collapse all</span>
    </a>
  </div>

  <mat-accordion multi="true">
    <ng-container *ngFor="let section of sections">
      <mat-expansion-panel [(expanded)]="section.isExpanded">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ section.title }}
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div *ngIf="section.id === 1">
          <rpc-status [uNumber]="umbrellaNumber"></rpc-status>
        </div>
        <div *ngIf="section.id === 2">
          <rpc-accrual [uNumber]="umbrellaNumber"></rpc-accrual>
        </div>
        <div *ngIf="section.id === 3">
          <rpc-protocol-materials [uNumber]="umbrellaNumber" [hideTitle]=true></rpc-protocol-materials>
        </div>
        <div *ngIf="section.id === 4">
          <rpc-priority [uNumber]="umbrellaNumber"></rpc-priority>
        </div>
        <div *ngIf="section.id === 5">
          <rpc-categorization [uNumber]="umbrellaNumber"></rpc-categorization>
        </div>

      </mat-expansion-panel>
    </ng-container>
  </mat-accordion>
</div>
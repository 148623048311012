import { AfterViewInit, Component, ComponentRef, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable, Subscription, firstValueFrom, map, of } from 'rxjs';
import { Chip } from '../common/controls/chips-with-input/chips-with-input.component';
import { Node, NodeTreeService } from '../common/controls/node-tree/node-tree.service';
import { Categories } from '../common/models/categories';
import { CategoryPreference } from '../common/models/category-preference';
import { CategoryDetails } from '../common/models/catergory-details';
import { DiseaseGroupCategory } from '../common/models/disease-group-category';
import { SearchRequest } from '../common/models/search-request';
import { SearchResponse } from '../common/models/search-response';
import { SearchResultsDataSource } from '../common/models/search-results-data-source';
import { UserFilter } from '../common/models/user-filter';
import { UserFilterPreference } from '../common/models/user-filter-preference';
import { UserPreferences } from '../common/models/user-preferences';
import { AuthService } from '../common/services/auth.service';
import { LoadingService } from '../common/services/loading.service';
import { PreviewContentService } from '../common/services/preview-content.service';
import { SearchService } from '../common/services/search.service';
import { UserSearchPreferenceService } from '../common/services/user-search-preference.service';
import { SaveFilterDialogComponent } from '../dialog/save-filter-dialog/save-filter-dialog.component';
import { SearchResultsPreviewComponent } from '../search-results/search-results-preview/search-results-preview.component';
import { PreviewComponent } from '../common/layout/preview/preview.component';

@Component({
  selector: 'rpc-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy, AfterViewInit {

  searchResults!: SearchResponse;
  searchResultDataSource!: SearchResultsDataSource;

  breakpoint: number = 6;
  checkboxGridListColSpan: number = 3;
  checkboxGridRowSpan: number = 10;
  buttonGridColSpan: number = 2;
  buttonGridRowSpan: number = 2;

  areaOfInterestInputValues = { label: 'What departments\' or disease groups\' protocols are you looking for?', placeholder: "Click here to select or type" };
  additionalFiltersInputValues = { label: 'Keyword(s)', placeholder: "Type keyword(s) here" };
  protocolNumberInputValues = { label: '', placeholder: "Search by ID/# (Protocol, IRB, NCI, NCT)" };
  diseaseGroupCategoriesInputValues = { label: 'Disease group categorization filter(s)', placeholder: "Click here to select or type" };

  EMPTY_SEARCH_REQUEST: SearchRequest = {
    areaOfInterest: [],
    irbProtocol: [],
    status: [],
    location: [],
    phase: [],
    gender: [],
    population: [],
    additionalFilters: [],
    diseaseGroupCategories: [],
    studyType: [],
    sponsor: [],
    page: 1
  };
  searchRequest: SearchRequest = {
    areaOfInterest: [],
    irbProtocol: [],
    status: [],
    location: [],
    phase: [],
    gender: [],
    population: [],
    additionalFilters: [],
    diseaseGroupCategories: [],
    studyType: [],
    sponsor: [],
    page: 1
  };
  lastSearchRequest: SearchRequest = {
    areaOfInterest: [],
    irbProtocol: [],
    status: [],
    location: [],
    phase: [],
    gender: [],
    population: [],
    additionalFilters: [],
    diseaseGroupCategories: [],
    studyType: [],
    sponsor: [],
    page: 1
  };
  loadInitialSavedFilter: boolean = true;
  categories: Categories | undefined;
  categoryList: CategoryDetails[] | undefined;

  areaOfInterestChips: Chip[] = [];
  additionalFiltersChips: Chip[] = [];
  selectedDiseaseGroupCategories: Node[] = [];

  filterName: string = "";
  savedFilters: UserFilter[] = [];
  isTabletMode: boolean = false;

  areaOfInterestSuggestions: string[] = [];
  diseaseGroupSynonyms: Map<string, string[]> = new Map();
  diseaseGroupCategorizations: DiseaseGroupCategory[] = [];
  nodeDiseaseGroupCategorizations: Node[] = [];
  private subscriptions: Subscription[] = [];
  private searchResultsPreviewComponentRef!: ComponentRef<SearchResultsPreviewComponent>;
  private diseaseGroups: string[] = [];
  private isLoadingSearchCriteria: boolean = true;
  private isLoading: boolean = false;

  private readonly DISEASE_GROUP_DELIMITER: string = ' | ';
  private fromLastSearchReq: number = 0;
  private isDiseaseGroupNotFromLastSearch = false;

  constructor(private searchService: SearchService,
    private userSearchPreferenceService: UserSearchPreferenceService,
    private previewContentService: PreviewContentService,
    private saveDialog: MatDialog,
    private authService: AuthService,
    private nodeTreeService: NodeTreeService,
    private loadingService: LoadingService,
    private route: ActivatedRoute) {
  }

  ngOnInit(): void {


    this.route.queryParams.subscribe(params => {
      const isDelete = params['deleteFilter'];
      if (isDelete) {
        const defaultSearch = {
          areaOfInterest: [],
          irbProtocol: [],
          status: ["Accruing"],
          location: [],
          phase: [],
          gender: [],
          additionalFilters: [],
          population: ["All"],
          diseaseGroupCategories: [],
          studyType: ["Interventional"],
          sponsor: [],
          page: 1
        };
        this.searchService.searchProtocols(defaultSearch);
        this.previewContentService.previewEmitter.emit(true);
      }

    });

    this.subscriptions.push(
      this.loadingService.loadingSub$
        .subscribe((isLoading: boolean) => {
          this.isLoading = isLoading;
          if (!this.isLoading && this.loadingService.isSearchApiCall) {
            this.search();
          }
        })
    );

    // Load the previous search if it exists
    // Could be cleared out by another component to force loading a saved filter
    firstValueFrom(this.searchService.lastSearchRequest)
      .then(newSearchRequest => {

        // If there's no previous search, the first result will be null
        if (newSearchRequest) {
          if (newSearchRequest.page > 0) {
            this.fromLastSearchReq = newSearchRequest.page;
          }

          if (newSearchRequest !== this.searchRequest) {
            this.setSearchFiltersFromSearchRequest(newSearchRequest);
            this.loadInitialSavedFilter = false;
          }
        }

        // Only subscribe to the search service's selected preference once we hear back from the in-memory cache
        this.subscriptions.push(
          this.userSearchPreferenceService.selectedPreference.subscribe(
            (selectedFilter: UserFilter | null) => {
              if (this.loadInitialSavedFilter) {
                this.fromLastSearchReq = -1;
                if (selectedFilter) {
                  this.setSearchFiltersFromUserFilter(selectedFilter);
                } else {
                  this.newSearch();
                }
              }
              else {
                this.filterName = selectedFilter?.filterName ?? "";
                this.loadInitialSavedFilter = true;
              }
            }
          )
        );
      });

    this.onResize({ target: window });

    this.searchResultDataSource = new SearchResultsDataSource(this.searchService);

    if (this.userSearchPreferenceService.prefCategories == undefined) {
      this.subscriptions.push(
        this.userSearchPreferenceService.getCategoryDetails()
          .subscribe((result) => {
            this.categories = result;
            this.userSearchPreferenceService.prefCategories = this.categories;
          })
      );
    } else {
      this.categories = this.userSearchPreferenceService.prefCategories;
    }

    this.subscriptions.push(
      this.userSearchPreferenceService.getCategories()
        .subscribe((result) => {
          this.categoryList = result;
        })
    );

    this.subscriptions.push(
      this.userSearchPreferenceService.savedFilters
        .subscribe((savedFilters) => {
          this.savedFilters = savedFilters;
        })
    );

    this.subscriptions.push(
      this.searchService.getSearchSuggestions()
        .subscribe(suggestionObject => {
          const result = [];
          for (const key in suggestionObject.suggestions) {
            result.push(...suggestionObject.suggestions[key]);
            if (key === 'disease_groups') {
              this.diseaseGroups = suggestionObject.suggestions[key];
              this.updateDiseaseGroupCategorizations([], this.searchRequest.areaOfInterest);
            }
          }
          this.areaOfInterestSuggestions = result.sort();
          for (const [key, values] of Object.entries(suggestionObject.diseaseGroupSynonyms)) {
            this.diseaseGroupSynonyms.set(key, values);
          }
        })
    );
  }

  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
    this.searchResultsPreviewComponentRef?.destroy();
  }

  ngAfterViewInit(): void {

    this.searchResultsPreviewComponentRef = this.previewContentService.createComponent(SearchResultsPreviewComponent);
    if (this.searchResultsPreviewComponentRef.location) {
      this.searchResultsPreviewComponentRef.location.nativeElement.style = `
        display: block;
        height: inherit;
        `;
    }
    this.searchResultsPreviewComponentRef.instance.searchCriteria = this.searchRequest;
    this.searchResultsPreviewComponentRef.instance.dataSource = this.searchResultDataSource;
    this.searchResultDataSource.searchResponse$
      .subscribe(searchResponse => this.updateDataFromSearch(searchResponse));
  }

  onResize(event: any) {
    const widthBreakpoint: number = 992;
    const eventTargetWidth = event.target.innerWidth;
    const windowWidth = window.innerWidth;

    this.breakpoint = (eventTargetWidth <= widthBreakpoint) ? 4 : 6;
    this.checkboxGridListColSpan = (windowWidth <= widthBreakpoint) ? 3 : 2;
    this.checkboxGridRowSpan = (windowWidth <= widthBreakpoint) ? 15 : 15;
    this.buttonGridColSpan = (windowWidth <= widthBreakpoint) ? 1 : this.breakpoint;
    this.buttonGridRowSpan = (windowWidth <= widthBreakpoint) ? this.checkboxGridRowSpan : 2;
    this.isTabletMode = windowWidth <= widthBreakpoint;
  }

  /**
   *
   * @param categoryDetailsList Get Category display name
   * @returns
   */
  getCategoryName(categoryDetailsList: CategoryDetails[]) {
    return categoryDetailsList
      .find((obj: CategoryDetails) => { return true; })
      ?.categoryName;
  }

  /**
   * Sets the UI's fields and the search request object to the saved values, then searches
   * @param userFilter Saved filter being loaded
   */
  setSearchFiltersFromUserFilter(userFilter: UserFilter): void {
    this.isLoadingSearchCriteria = true;
    const originalAreaOfInterestList = Array.from(this.searchRequest.areaOfInterest);
    this.resetSearchCriteria();

    this.filterName = userFilter.filterName;

    for (const ufp of userFilter.userFilterPreferences) {
      switch (ufp.category) {
        case "Location":
          this.addSavedFilterToArray(ufp, this.searchRequest.location);
          break;
        case "Status":
          this.addSavedFilterToArray(ufp, this.searchRequest.status);
          break;
        case "Gender":
          this.addSavedFilterToArray(ufp, this.searchRequest.gender);
          break;
        case "Area of Interest":
          this.addSavedFilterToArray(ufp, this.searchRequest.areaOfInterest, this.areaOfInterestChips);
          break;
        case "Phase":
          this.addSavedFilterToArray(ufp, this.searchRequest.phase);
          break;
        case "Additional Filters":
          this.addSavedFilterToArray(ufp, this.searchRequest.additionalFilters, this.additionalFiltersChips);
          break;
        case "Disease Group Categories":
          this.addSavedFilterToArray(ufp, this.searchRequest.diseaseGroupCategories);
          break;
        case "Study Type":
          this.addSavedFilterToArray(ufp, this.searchRequest.studyType);
          break;
        case "Sponsor":
          this.addSavedFilterToArray(ufp, this.searchRequest.sponsor);
          break;
        case "Population":
          this.addSavedFilterToArray(ufp, this.searchRequest.population);
          break;
        default:
          break;
      }
    }

    this.updateDiseaseGroupCategorizations(originalAreaOfInterestList, this.searchRequest.areaOfInterest);

    this.isLoadingSearchCriteria = false;
    this.search();
  }

  /**
   * Overwrites the current search settings with the given SearchRequest
   * @param searchRequest SearchRequest object that should be applied to the current search criteria
   */
  setSearchFiltersFromSearchRequest(searchRequest: SearchRequest): void {
    this.isLoadingSearchCriteria = true;
    const originalAreaOfInterestList = Array.from(this.searchRequest.areaOfInterest);
    this.resetSearchCriteria();
    this.copySearchRequest(this.searchRequest, searchRequest);
    let currentId = -1;

    this.searchRequest.areaOfInterest?.forEach(chipValue => {
      this.areaOfInterestChips.push({ id: currentId--, value: chipValue });
    });

    this.searchRequest.additionalFilters?.forEach(chipValue => {
      this.additionalFiltersChips.push({ id: currentId--, value: chipValue });
    });

    this.updateDiseaseGroupCategorizations(originalAreaOfInterestList, this.searchRequest.areaOfInterest);

    this.isLoadingSearchCriteria = false;
    this.search(true);
  }

  /**
   * Reset Search Criteria
   */
  resetSearchCriteria() {
    this.copySearchRequest(this.searchRequest, this.EMPTY_SEARCH_REQUEST);
    this.areaOfInterestChips = [];
    this.additionalFiltersChips = [];
    this.selectedDiseaseGroupCategories = [];
  }

  /**
   * This will clear the search and default the status to accruing.
   *
   */
  newSearch() {
    const emptyFilter: UserFilter = {
      email: '',
      filterName: '',
      modifiedTs: '',
      userFilterId: -1,
      userFilterPreferences: [{
        "userPrefId": -1,
        "category": "Status",
        "categoryValue": "Accruing"
      }, {
        "userPrefId": -1,
        "category": "Study Type",
        "categoryValue": "Interventional",
      }, {
        "userPrefId": -1,
        "category": "Population",
        "categoryValue": "All",
      }],
      show: false
    };
    this.userSearchPreferenceService.setClearSearch(true);
    this.userSearchPreferenceService.setSelectedPreference(emptyFilter);

  }

  /**
   * Verifies that at least one area of interest is a disease group, otherwise returns false
   * @returns If the disease group categorizations search box should be shown
   */
  showDiseaseGroupCategorizations(): boolean {
    return this.searchRequest.areaOfInterest.some(aoi => this.diseaseGroups.includes(aoi));
  }

  /**
   * Verifies that at least one area of interest is a department, otherwise returns false
   * @returns If the additional filter search box should be shown
   */
  showAdditionalFilter(): boolean {
    return this.searchRequest.areaOfInterest.some(aoi => !this.diseaseGroups.includes(aoi));
  }

  /**
   * Adds the new disease group category to the nodeDiseaseGroupCategorization list creating a new list in the process
   * @param diseaseGroupCategory New disease group category to convert to Node
   */
  addNodeDiseaseGroupCategorization(diseaseGroupCategory: DiseaseGroupCategory[]): void {

    const nodes: Node[] = Array.from(this.nodeDiseaseGroupCategorizations);

    const getChildren: (diseaseSubGroups: DiseaseGroupCategory[]) => Node[]
      = (diseaseSubGroups: DiseaseGroupCategory[]): Node[] => {
        const result = [];
        for (const subGroup of diseaseSubGroups) {
          result.push({
            item: subGroup.category,
            children: getChildren(subGroup.children)
          });
        }
        return result.sort(this.nodeTreeService.sortNode);
      };

    for (const dsc of diseaseGroupCategory) {
      const newNode: Node = {
        item: dsc.category,
        children: getChildren(dsc.children)
      };

      const isDuplicate: boolean = nodes.some((node: Node) => {
        if (node.item === newNode.item) {
          this.nodeDiseaseGroupCategorizations.forEach(d => {
            if (d.item === node.item) {
              if (d.children && newNode.children && newNode.children[0]) {
                d.children.push(newNode.children[0]); // Add newNode to the children array
              }

            }
          });
        }
        return node.item === newNode.item;
      });

      if (!isDuplicate) {
        nodes.push(newNode);
      }
    }


    this.nodeDiseaseGroupCategorizations = nodes.sort(this.nodeTreeService.sortNode);
  }


  /**
   * Removes the given disease group from the nodeDiseaseGroupCategorization list creating a new list in the process
   * @param diseaseGroup Name of the disease group to remove
   */
  removeNodeDiseaseGroupCategorization(diseaseGroup: string): void {

    const index = this.nodeDiseaseGroupCategorizations.findIndex(ndgc => ndgc.item === diseaseGroup);
    // Remove from the possible disease group categorizations

    if (this.diseaseGroups.includes(diseaseGroup) && index > -1) {
      this.nodeDiseaseGroupCategorizations = this.nodeDiseaseGroupCategorizations.slice(0, index).concat(this.nodeDiseaseGroupCategorizations.slice(index + 1));

      // Check for the "Radiation" item in children
      for (const node of this.nodeDiseaseGroupCategorizations) {
        if (node.children) {
          const radiationIndex = node.children.findIndex(child => child.item === diseaseGroup);
          if (radiationIndex > -1) {
            if (node.children.length === 1) {
              // Remove the whole object if children contain only one item
              this.nodeDiseaseGroupCategorizations = this.nodeDiseaseGroupCategorizations.filter(ndgc => ndgc !== node);

              const radiationList = this.nodeTreeService.convertNodeToDelimitedStrings(node, this.DISEASE_GROUP_DELIMITER);
              radiationList.forEach(res => {
                if (this.searchRequest.diseaseGroupCategories.includes(res)) {
                  const index = this.searchRequest.diseaseGroupCategories.indexOf(res);
                  if (index !== -1) {
                    this.searchRequest.diseaseGroupCategories.splice(index, 1);
                  }
                }
              });

            } else {
              // Remove the matching "Breast" item
              node.children = node.children.slice(0, radiationIndex)
                .concat(node.children.slice(radiationIndex + 1));
            }
          }
        }
      }

    }

    // Remove from the search request
    if (this.searchRequest.diseaseGroupCategories.length > 0) {
      this.searchRequest.diseaseGroupCategories = this.searchRequest.diseaseGroupCategories.filter(
        selectedCategory => selectedCategory.split(this.DISEASE_GROUP_DELIMITER)[0] !== diseaseGroup
      );
    }
  }

  /**** Event Handlers ****/

  /**
   * Handles a protocol or IRB number selected in the search box and runs a search for only that number
   * @param number Protocol or IRB Number that should be searched
   */
  handleProtocolIRBSearch(protocolIrbNumber: string): void {
    this.fromLastSearchReq = -1;
    if (!!protocolIrbNumber) {
      const newSearchCriteria: SearchRequest = {} as SearchRequest;
      this.copySearchRequest(newSearchCriteria, this.EMPTY_SEARCH_REQUEST);
      newSearchCriteria.irbProtocol = [protocolIrbNumber.toUpperCase()];
      const emptyFilter: UserFilter = {
        email: '',
        filterName: '',
        modifiedTs: '',
        userFilterId: -1,
        userFilterPreferences: [],
        show: false
      };
      this.userSearchPreferenceService.setClearSearch(true);
      this.userSearchPreferenceService.setSelectedPreference(emptyFilter);
      this.searchRequest = newSearchCriteria;
      this.search();
    }
  }

  /**
   * Sets the given search request array to the chips and calls the search
   * @param requestArray To which array in the SearchRequest object the chips need to be added
   * @param chips The new list of chips for the given search field
   */
  handleChipsChange(requestArray: string[], chips: Chip[]): void {
    this.fromLastSearchReq = -1;
    const mappedChips = chips.map(c => c.value);
    // If the arrays are identical, no need to update or run the search
    if (mappedChips.length === requestArray.length && mappedChips.every(chip => requestArray.includes(chip))) {
      return;
    }
    requestArray.splice(0, requestArray.length, ...mappedChips);
    this.searchRequest.irbProtocol = [];
    this.search();
  }

  /**
   * Handles a change in the chips for the area of interest - Performs the shared handleChipsChange then updates the disease group categories appropriately
   * @param chips new list of chips ($event)
   */
  handleAreaOfInterestChipsChange(chips: Chip[]): void {
    this.fromLastSearchReq = -1;
    const originalArray = Array.from(this.searchRequest.areaOfInterest);
    this.handleChipsChange(this.searchRequest.areaOfInterest, chips);
    this.updateDiseaseGroupCategorizations(originalArray, this.searchRequest.areaOfInterest);
  }

  /**
   * Updates the search criteria for disease group categorizations based on the selected nodes
   * @param nodesSelected Nodes selected from the disease group categorization tree
   */
  handleDiseaseGroupCategorizationSelectionChanges(pipeDelimitedCategorization: string[]): void {
    if (this.isDiseaseGroupNotFromLastSearch) {
      this.fromLastSearchReq = -1;
    }
    if (this.fromLastSearchReq > 0)
      this.isDiseaseGroupNotFromLastSearch = true;

    this.searchRequest.diseaseGroupCategories = pipeDelimitedCategorization;
    this.searchRequest.irbProtocol = [];
    this.search();
  }

  /**
   * Updates the given array to add/remove item being affected based on the event
   * @param requestArray To which array in the SearchRequest object the selected/deselected value should be added/removed
   * @param event Change event from checkbox
   * @param item CategoryDetails item being affected
   */
  handleCategorySelectionChange(requestArray: string[], event: MatCheckboxChange, item: CategoryDetails): void {
    this.fromLastSearchReq = -1;
    if (requestArray.includes(item.categoryValue)) {
      if (!event.checked) {
        const index: number = requestArray.indexOf(item.categoryValue);
        requestArray.splice(index, 1);
      }
    }
    else if (event.checked) {
      requestArray.push(item.categoryValue);
    }
    this.searchRequest.irbProtocol = [];
    this.search();
  }

  /**
   * Returns a function that the chips-with-input component can use to update the search suggestions
   * @param field Field that needs this function
   * @returns A function that calls the backend for search results given a search string
   */
  getSuggestionsFunction(field: string): (searchString: string) => Observable<string[]> {
    switch (field) {
      case 'areaOfInterest':
        return (searchString: string): Observable<string[]> => {
          const currentAreaOfInterestSuggestions: string[] =
            this.areaOfInterestSuggestions.filter(aois => !this.searchRequest.areaOfInterest.includes(aois));
          if (searchString) {
            const filterValue = searchString.toLowerCase();
            const result: string[] = currentAreaOfInterestSuggestions.filter(sug => sug.toLowerCase().includes(filterValue));
            const keysWithMatchingValues: string[] = [];
            for (const [key, synonyms] of this.diseaseGroupSynonyms.entries()) {
              if (synonyms.some(synonym => synonym.toLowerCase().includes(filterValue))) {
                keysWithMatchingValues.push(key);
              }
            }
            const filteredDiseaseGroupSynonyms: string[] = keysWithMatchingValues.filter(aois => !this.searchRequest.areaOfInterest.includes(aois));
            const combinedSuggestions: string[] = [...result, ...filteredDiseaseGroupSynonyms];
            const uniqueArray: string[] = this.removeDuplicatesFromArray(combinedSuggestions);
            return of(uniqueArray);
          }
          else {
            return of(currentAreaOfInterestSuggestions);
          }
        };
      default:
        return (searchString: string): Observable<string[]> => {
          if (searchString) {
            return this.searchService.getSearchSuggestions(field, searchString)
              .pipe(
                map((result =>
                  // filter results, not to show what user has already selected
                  result.suggestions['protocol_number'].filter(p => !this.searchRequest.irbProtocol.includes(p))))
              );
          }
          else {
            return of([]);
          }
        };
    }
  }

  removeDuplicatesFromArray(arr: string[]): string[] {
    return Array.from(new Set(arr));
  }

  /**
   *
   * @returns Disable save button
   */
  disableSaveButton() {
    if (this.searchRequest.areaOfInterest.length === 0 &&
      this.searchRequest.irbProtocol.length === 0 &&
      this.searchRequest.additionalFilters.length === 0 &&
      this.searchRequest.gender.length === 0 &&
      this.searchRequest.location.length === 0 &&
      this.searchRequest.phase.length === 0 &&
      this.searchRequest.status.length === 0 &&
      this.searchRequest.studyType.length === 0 &&
      this.searchRequest.sponsor.length === 0 &&
      this.searchRequest.population.length === 0) {
      return true;
    }
    return false;
  }

  /**
   * Open save filter dialog box
   */
  saveFilter() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.backdropClass = 'modal-backdrop';
    dialogConfig.data = {
      filterName: this.filterName,
      searchRequest: this.searchRequest
    };
    const dialogRef = this.saveDialog.open(SaveFilterDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
      data => {
        if (data) {
          this.filterName = dialogConfig.data?.filterName;
          const userPreferences: UserPreferences = this.convertToUserFilter(this.searchRequest);
          this.subscriptions.push(
            this.userSearchPreferenceService.createUserSearchFilter(userPreferences).subscribe((result) => {
              this.userSearchPreferenceService.buildUserFilters(result)
                .forEach(filter => {
                  this.addOrUpdateSavedFilters(filter);
                  this.userSearchPreferenceService.setSelectedPreference(filter);
                });
            })
          );
        }
      });
  }

  /**
   * Add or update saved filters
   * @param newFilter
   */
  addOrUpdateSavedFilters(newFilter: UserFilter) {
    //Find the index of existing filter with same name as current filter and replace it
    //If existing filter name is not found then add current filter to top of saved filter list
    let index: number = this.savedFilters.findIndex((x => x.filterName === this.filterName));
    if (index >= 0) {
      if (index === 0) {
        this.savedFilters[index].show = false;
        setTimeout(() => {
          this.savedFilters[index].userFilterPreferences = newFilter.userFilterPreferences;
          this.savedFilters[index].show = true;
        }, 500);
      } else {
        this.savedFilters.splice(index, 1);
        this.savedFilters.unshift(newFilter);
      }

    } else {
      // Add filter to top of savedFilters list
      this.savedFilters.unshift(newFilter);
    }
  }

  /**
   * Convert search criteria to user filters for save filter
   * @param searchRequest
   * @returns
   */
  convertToUserFilter(searchRequest: SearchRequest): UserPreferences {

    let userPreferences: UserPreferences = {
      email: this.authService.getUserAccountInfo()?.username || '',
      filterName: this.filterName,
      categoryPreferences: []
    };

    if (searchRequest.location?.length > 0) {
      let categoryId: number = this.findCategoryId("Location");
      this.setCategory(categoryId, searchRequest.location, userPreferences);
    }

    if (searchRequest.status?.length > 0) {
      let categoryId: number = this.findCategoryId("Status");
      this.setCategory(categoryId, searchRequest.status, userPreferences);
    }

    if (searchRequest.gender?.length > 0) {
      let categoryId: number = this.findCategoryId("Gender");
      this.setCategory(categoryId, searchRequest.gender, userPreferences);
    }

    if (searchRequest.population?.length > 0) {
      let categoryId: number = this.findCategoryId("Population");
      this.setCategory(categoryId, searchRequest.population, userPreferences);
    }

    if (searchRequest.phase?.length > 0) {
      let categoryId: number = this.findCategoryId("Phase");
      this.setCategory(categoryId, searchRequest.phase, userPreferences);
    }

    if (searchRequest.areaOfInterest?.length > 0) {
      let categoryId: number = this.findCategoryId("Area of Interest");
      this.setCategory(categoryId, searchRequest.areaOfInterest, userPreferences);
    }

    if (searchRequest.additionalFilters?.length > 0) {
      let categoryId: number = this.findCategoryId("Additional Filters");
      this.setCategory(categoryId, searchRequest.additionalFilters, userPreferences);
    }

    if (searchRequest.diseaseGroupCategories?.length > 0) {
      let categoryId: number = this.findCategoryId("Disease Group Categories");
      this.setCategory(categoryId, searchRequest.diseaseGroupCategories, userPreferences);
    }

    if (searchRequest.studyType?.length > 0) {
      let categoryId: number = this.findCategoryId("Study Type");
      this.setCategory(categoryId, searchRequest.studyType, userPreferences);
    }

    if (searchRequest.sponsor?.length > 0) {
      let categoryId: number = this.findCategoryId("Sponsor");
      this.setCategory(categoryId, searchRequest.sponsor, userPreferences);
    }

    return userPreferences;
  }

  updateSelectedDiseaseGroupCategories(): void {
    const tempNodes: Node[] = [];
    for (const diseaseGroupString of this.searchRequest.diseaseGroupCategories) {
      const n = this.nodeTreeService.convertDelimitedStringToNode(diseaseGroupString, this.DISEASE_GROUP_DELIMITER);
      if (n) {
        tempNodes.push(n);
      }
    }

    this.selectedDiseaseGroupCategories = tempNodes;
  }

  /**
   * Set categories to user preference
   * @param categoryId
   * @param searchField
   * @param userPreferences
   */
  setCategory(categoryId: number, searchField: string[], userPreferences: UserPreferences) {
    for (const each of searchField) {
      let categoryPreference: CategoryPreference = {
        preferenceCategoryID: categoryId,
        preferenceValue: each
      };
      userPreferences.categoryPreferences.push(categoryPreference);
    }
  }

  /**
   * Find categoryId
   * @param categoryName
   * @returns
   */
  findCategoryId(categoryName: string): number {
    return this.categoryList?.find(x => x.categoryName === categoryName)?.preferenceCategoryId || 0;
  }

  /**
   * Get categoryName by Id
   * @param categoryId
   * @returns
   */
  findCategoryName(categoryId: number): string {
    return this.categoryList?.find(x => x.preferenceCategoryId === categoryId)?.categoryName || "";
  }

  /**
  * This is to check the checkbox is selected
   * @param requestArray
   * @param item
   * @returns
   */
  isChecked(requestArray: string[], item: CategoryDetails): boolean {
    if (item?.categoryValue) {
      if (requestArray?.includes(item?.categoryValue)) {
        return true;
      }
    }
    return false;
  }

  /**
   * Combine the disease group results, so we can group them accordingly.
   *
   * @param dgLocal
   * @param dgBackend
   * @returns
   */
  mergeDiseaseGroup(dgLocal: DiseaseGroupCategory[], dgBackend: DiseaseGroupCategory[]): DiseaseGroupCategory[] {

    const merged: DiseaseGroupCategory[] = dgLocal.slice();
    dgBackend.forEach(dgB => {
      const existingItem = merged.find(dgL => dgL.category === dgB.category);
      if (existingItem) {
        existingItem.children.push(dgB.children[0]);
      } else {
        merged.push(dgB);
      }
    });

    return merged;

  }

  /**
   * Used to set the value of the IRB Protocol text box after searching
   * @returns the searched IRB protocol value or null
   */
  getIRBProtocolSearchValue(): string | null {
    if (this.searchRequest?.irbProtocol?.length > 0) {
      return this.searchRequest.irbProtocol[0];
    }
    return null;
  }

  /**
   * Perform search using searchRequest object
   */
  private search(keepPage?: boolean): void {
    if (!this.isLoadingSearchCriteria && !this.isLoading) {
      if (!keepPage) {
        this.searchRequest.page = 1;
      }

      if (this.fromLastSearchReq > 0) {
        this.searchRequest.page = this.fromLastSearchReq;
      }

      if (!this.areSearchRequestsEqual(this.lastSearchRequest, this.searchRequest)) {
        this.copySearchRequest(this.lastSearchRequest, this.searchRequest);
        this.searchResultDataSource.search(this.searchRequest);
      } else {
        this.searchService.setLastSearchRequest(this.searchRequest);
      }
    }
  }

  private updateDataFromSearch(searchResponse: SearchResponse): void {
    this.searchResults = searchResponse;
  }

  /**
   * Adds the UserFilterPreference information to the given arrays
   * @param userFilterPreference UserFilterPreference from the DB
   * @param searchRequestArray Array within the SearchRequest object that should be updated
   * @param chipsArray Array of Chips mapped to the chips with input component (optional)
   */
  private addSavedFilterToArray(userFilterPreference: UserFilterPreference, searchRequestArray: string[], chipsArray?: Chip[]): void {
    searchRequestArray.push(userFilterPreference.categoryValue);
    if (chipsArray) {
      chipsArray.push({ id: userFilterPreference.userPrefId, value: userFilterPreference.categoryValue });
    }
  }

  /**
   * Finds the differences between the 2 array and either adds or removes any disease group categories from the tree
   * @param originalArray Array with the original list of categories before the change
   * @param newArray Array of the new categories
   */
  private updateDiseaseGroupCategorizations(originalArray: string[], newArray: string[]): void {

    const newItems =
      newArray.filter(aoi => !originalArray.includes(aoi));
    if (newItems?.length > 0) {
      for (const iterator of newItems) {
        if (this.diseaseGroups.includes(iterator)
          && !this.diseaseGroupCategorizations.some(dgc => dgc.category === iterator)) { // Don't add duplicate fields
          this.subscriptions.push(
            this.searchService.getDiseaseGroupCategories(iterator)
              .subscribe(result => {
                if (this.diseaseGroupCategorizations.length === 0) {
                  this.diseaseGroupCategorizations = result;
                } else {
                  this.diseaseGroupCategorizations = this.mergeDiseaseGroup(this.diseaseGroupCategorizations, result);
                }
                this.addNodeDiseaseGroupCategorization(result);
              })
          );
        }
      }
    }
    const removedItems = originalArray.filter(oa => !newArray.includes(oa));
    if (removedItems?.length > 0) {
      for (const iterator of removedItems) {
        const index = this.diseaseGroupCategorizations.findIndex(dgc => dgc.category === iterator);
        this.diseaseGroupCategorizations.splice(index, 1);

        this.removeNodeDiseaseGroupCategorization(iterator);
      }
    }
    this.updateSelectedDiseaseGroupCategories();
  }



  /**
   * Overwrites the destination's values with the source's values
   * @param destination Search Request that will be updated to match the source
   * @param source Search Request that will have its data copied to the destination
   */
  private copySearchRequest(destination: SearchRequest, source: SearchRequest): void {
    destination.areaOfInterest = [...source.areaOfInterest];
    destination.irbProtocol = [...source.irbProtocol];
    destination.status = [...source.status];
    destination.location = [...source.location];
    destination.phase = [...source.phase];
    destination.gender = [...source.gender];
    destination.population = [...source.population];
    destination.additionalFilters = [...source.additionalFilters];
    destination.diseaseGroupCategories = [...source.diseaseGroupCategories];
    destination.studyType = [...source.studyType];
    destination.sponsor = [...source.sponsor];
    destination.page = source.page;
  }

  private areSearchRequestsEqual(searchRequest1: SearchRequest, searchRequest2: SearchRequest): boolean {
    let countsMatch = searchRequest1.additionalFilters.length === searchRequest2.additionalFilters.length
      && searchRequest1.areaOfInterest.length === searchRequest2.areaOfInterest.length
      && searchRequest1.diseaseGroupCategories.length === searchRequest2.diseaseGroupCategories.length
      && searchRequest1.gender.length === searchRequest2.gender.length
      && searchRequest1.population.length === searchRequest2.population.length
      && searchRequest1.irbProtocol.length === searchRequest2.irbProtocol.length
      && searchRequest1.location.length === searchRequest2.location.length
      && searchRequest1.phase.length === searchRequest2.phase.length
      && searchRequest1.studyType.length === searchRequest2.studyType.length
      && searchRequest1.sponsor.length === searchRequest2.sponsor.length
      && searchRequest1.status.length === searchRequest2.status.length;

    if (countsMatch && searchRequest1.page === searchRequest2.page) {
      const checkForMissingValues =
        (array1: string[], array2: string[]): boolean => {
          for (const s of array1) {
            if (!array2.includes(s)) {
              return false;
            }
          }
          return true;
        };

      for (const key in searchRequest1) {
        if (key !== 'page') {
          const searchArray1 = (searchRequest1 as any)[key] as string[];
          const searchArray2 = (searchRequest2 as any)[key] as string[];
          if (!checkForMissingValues(searchArray1, searchArray2) || !checkForMissingValues(searchArray2, searchArray1)) {
            return false;
          }
        }
      }
    }
    else {
      return false;
    }
    return true;
  }
}

<div class="top-bar">
    <div class="button-container">
        <button mat-raised-button (click)="newSearch()" class="button filter-button font-normal clear-filters">
            Clear Search
        </button>
        <button class="button filter-button font-normal" mat-raised-button (click)="saveFilter()"
            [disabled]="disableSaveButton()">
            Save Filter
        </button>
    </div>
    <div class="protocol-irb-number-div">
        <rpc-search-input class="protocol-irb-number" [placeholder]="protocolNumberInputValues.placeholder"
            [labelName]="protocolNumberInputValues.label" [requireSuggestion]="false"
            [getSuggestionsFunction]="getSuggestionsFunction('irbProtocol')"
            (suggestionSelectionChange)="handleProtocolIRBSearch($event)"
            [value]="getIRBProtocolSearchValue()"></rpc-search-input>
    </div>
</div>
<div class="landing-tiles">
    <mat-grid-list [cols]="breakpoint" rowHeight="10px" gutterSize="20px" (window:resize)="onResize($event)">
        <mat-grid-tile class="search-tile" [colspan]=4 [rowspan]=15>
            <div class="search-info">
                Press return or enter after each search term to filter results
            </div>
            <rpc-chips-with-input tabindex="1" [placeholder]="areaOfInterestInputValues.placeholder"
                [labelName]="areaOfInterestInputValues.label" [requireSuggestion]="true"
                [getSuggestionsFunction]="getSuggestionsFunction('areaOfInterest')"
                (chipsChange)="handleAreaOfInterestChipsChange($event)" [chips]="areaOfInterestChips"
                [shouldUpdateAutoSuggestionOnClick]=true>
            </rpc-chips-with-input>
            <div class="disease-group-categories" *ngIf="showDiseaseGroupCategorizations()">
                <rpc-chips-with-input tabindex="2" [placeholder]="diseaseGroupCategoriesInputValues.placeholder"
                    [labelName]="diseaseGroupCategoriesInputValues.label" [treeNodes]="nodeDiseaseGroupCategorizations"
                    [selectedTreeNodes]="selectedDiseaseGroupCategories"
                    (treeChipsSelectedChange)="handleDiseaseGroupCategorizationSelectionChanges($event)">
                </rpc-chips-with-input>
            </div>
            <div>
                <rpc-chips-with-input tabindex="3" [placeholder]="additionalFiltersInputValues.placeholder"
                    [labelName]="additionalFiltersInputValues.label"
                    (chipsChange)="handleChipsChange(searchRequest.additionalFilters, $event)"
                    [chips]="additionalFiltersChips">
                </rpc-chips-with-input>
            </div>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="checkboxGridListColSpan" [rowspan]="checkboxGridRowSpan" class="checkbox-grid">
            <!--<table class="checkbox-table">  <- when sponsor field is coming back, uncomment this table and remove below table
            <tr>
              <td>
                <ng-template *ngTemplateOutlet="category; context: {details: categories?.location, requestArray: searchRequest.location }">
                </ng-template>
                <ng-template *ngTemplateOutlet="category; context: {details: categories?.studyType, requestArray: searchRequest.studyType }">
                </ng-template>
                <ng-template *ngTemplateOutlet="category; context: {details: categories?.population, requestArray: searchRequest.population }">
                </ng-template>
              </td>
              <td>
                <ng-template *ngTemplateOutlet="category; context: {details: categories?.status, requestArray: searchRequest.status}">
                </ng-template>
                <ng-template *ngTemplateOutlet="category; context: {details: categories?.sponsor, requestArray: searchRequest.sponsor }">
                </ng-template>
              </td>
              <td>
                <ng-template *ngTemplateOutlet="category; context: {details: categories?.phase, requestArray: searchRequest.phase}">
                </ng-template>
                <ng-template *ngTemplateOutlet="category; context: {details: categories?.gender, requestArray: searchRequest.gender }">
                </ng-template>
              </td>
            </tr>
          </table>-->
            <table class="checkbox-table">
                <tr>
                    <td>
                        <ng-template
                            *ngTemplateOutlet="category; context: {details: categories?.location, requestArray: searchRequest.location }">
                        </ng-template>
                    </td>
                    <td>
                        <ng-template
                            *ngTemplateOutlet="category; context: {details: categories?.status, requestArray: searchRequest.status}">
                        </ng-template>
                    </td>
                </tr>
                <tr>
                    <td>
                        <ng-template
                            *ngTemplateOutlet="category; context: {details: categories?.studyType, requestArray: searchRequest.studyType }">
                        </ng-template>
                    </td>
                    <td>
                        <ng-template
                            *ngTemplateOutlet="category; context: {details: categories?.phase, requestArray: searchRequest.phase}">
                        </ng-template>
                    </td>
                </tr>
                <tr>
                    <td>
                        <ng-template
                            *ngTemplateOutlet="category; context: {details: categories?.gender, requestArray: searchRequest.gender }">
                        </ng-template>
                    </td>
                    <td>
                        <ng-template
                            *ngTemplateOutlet="category; context: {details: categories?.population, requestArray: searchRequest.population }">
                        </ng-template>
                    </td>
                </tr>
            </table>
        </mat-grid-tile>
    </mat-grid-list>
</div>

<ng-template #category let-details="details" let-requestArray="requestArray">
    <div class="category" *ngIf="details">
        <span class="categoryName">{{getCategoryName(details)}}</span>
        <ul>
            <div *ngFor="let item of details">
                <mat-checkbox (change)="handleCategorySelectionChange(requestArray, $event, item)"
                    [checked]="isChecked(requestArray, item)">
                    <ng-container [ngSwitch]="item.categoryValue">
                        <ng-container *ngSwitchCase="'Minority'">
                            {{item.categoryValue}}
                            <mat-icon
                                matTooltip="Originates from PLM question ‘Is this a study targeting an under-represented minority?’  Minority may include, but not limited to: Native American, African American, Hispanic/Latino, LGBTQIA+, and people with disabilities."
                                matTooltipPosition="left" inline>help_outline</mat-icon>
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                            {{item.categoryValue}}
                        </ng-container>
                    </ng-container>
                </mat-checkbox>
            </div>
        </ul>
    </div>
</ng-template>
import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import * as fileSaver from 'file-saver';
import { Subscription } from 'rxjs';
import { FileNode } from 'src/app/common/models/file-node';
import { DetailsService } from 'src/app/common/services/details.service';

@Component({
  selector: 'rpc-protocol-documents',
  templateUrl: './more-info-protocol-documents.component.html',
  styleUrls: ['./more-info-protocol-documents.component.scss']
})
export class MoreInfoProtocolDocumentsComponent implements OnInit, OnDestroy {

  @Input()
  public uNumber!: string;

  nestedTreeControl: NestedTreeControl<FileNode>;
  nestedDataSource: MatTreeNestedDataSource<FileNode>;
  public protocolDocuments: FileNode[] = [];
  private subscriptionList: Subscription[] = [];
 



  constructor(private detailsService: DetailsService) {
    this.nestedTreeControl = new NestedTreeControl<FileNode>(this._getChildren);
    this.nestedDataSource = new MatTreeNestedDataSource();
  }
  ngOnInit(): void {
    this.subscriptionList.push(
      this.detailsService
        .getProtocolDocuments(this.uNumber)
        .subscribe((res) => {
          this.protocolDocuments = res.protocolFile?.fileNodes;
          this.nestedDataSource.data = this.protocolDocuments;
        })
    );
  }

  
  ngOnDestroy(): void {
    this.subscriptionList.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  hasNestedChild = (_: number, nodeData: FileNode) =>
    nodeData.childrenNodes && nodeData.childrenNodes.length > 0;


  /**
   * This will download the file as pdf for the passed in file location.
   *
   * @param  {FileNode} node - contains location of the file.
   */
  downloadProtocolDocuments(node: FileNode) {
    if (node.location != null) {
      this.subscriptionList.push(
        this.detailsService
          .getProtocolMaterialDoc(this.uNumber, node.source, node.location)
          .subscribe((res) => {
            this.downloadFile(res, node.name);
          })
      );
    }
  }

  /**
   * THis will allow to Capitalize the first char, since we have small char as first element in some cases ex : mayo converted to Mayo
   * 
   * @param folderName Parent Node Name
   * @returns 
   */
   capitalizeFirstLetter(folderName: string) {
    return folderName.charAt(0).toUpperCase() + folderName.slice(1);
}

  private _getChildren = (node: FileNode) => node.childrenNodes;

  /**
   * This will download the file using filesaver package.
   *
   * @param  {any} response
   * @param  {string} fileName?
   */
  private downloadFile(file: Blob, fileName: string) {
    //Filesaver to save to loal file system.
    fileSaver.saveAs(file, fileName);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppConfigService } from 'src/app/app-config/app-config.service';
import { Categories } from '../models/categories';
import { CategoryDetails } from '../models/catergory-details';
import { UserFilter } from '../models/user-filter';
import { UserFilterPreference } from '../models/user-filter-preference';
import { UserPreferences } from '../models/user-preferences';
import { UserSearchFilterResponse } from '../models/user-search-filter-response';

@Injectable({
  providedIn: 'root'
})
export class UserSearchPreferenceService {

  private _selectedPreference: BehaviorSubject<UserFilter | null> = new BehaviorSubject<UserFilter | null>(null);
  private _savedFilters: BehaviorSubject<UserFilter[]> = new BehaviorSubject<UserFilter[]>([]);
  private _clearSearch  : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private _prefCategories!: Categories;

  public set prefCategories(_prefCategories: Categories){
    this._prefCategories = _prefCategories;
  }

  public get prefCategories(){
    return this._prefCategories;
  }


  get selectedPreference(): Observable<UserFilter | null> {
    return this._selectedPreference.asObservable();
  }

  get savedFilters(): Observable<UserFilter[]> {
    return this._savedFilters.asObservable();
  }

  constructor(private http: HttpClient) { }

  /**
   * Retrieves the user's saved search filters from the DB
   */
  getUserSearchFilters(): Observable<UserSearchFilterResponse[]> {
    return this.http.get<UserSearchFilterResponse[]>(`${AppConfigService.settings?.rpcBackendUriV1}user-preferences`);
  }

  /**
   * Save filter
   * @param userPreferences 
   * @returns 
   */
  createUserSearchFilter(userPreferences: UserPreferences): Observable<UserSearchFilterResponse[]> {
    return this.http.post<UserSearchFilterResponse[]>(`${AppConfigService.settings?.rpcBackendUriV1}user-preferences`, userPreferences);
  }

  /**
   * Delete user filter
   * @param userFilterId 
   * @returns 
   */
  deleteUserFilter(userFilterId: number): Observable<Object> {
    return this.http.delete(`${AppConfigService.settings?.rpcBackendUriV1}user-preferences?userFilterId=${userFilterId}`);
  }

  /**
   * 
   * @returns Get Category details
   */
  getCategoryDetails(): Observable<Categories> {
    return this.http.get<Categories>(`${AppConfigService.settings?.rpcBackendUriV1}user-preferences/category-details`);
  }

  /**
   * 
   * @returns Get categories
   */
  getCategories(): Observable<CategoryDetails[]> {
    return this.http.get<CategoryDetails[]>(`${AppConfigService.settings?.rpcBackendUriV1}user-preferences/categories`);
  }

  setSelectedPreference(userFilter: UserFilter): void {
    this._selectedPreference.next(userFilter);
  }

  get clearSearch(): Observable<boolean> {
    return this._clearSearch.asObservable();
  }

  setClearSearch(value: boolean): void {
    this._clearSearch.next(value);
  }

  setSavedFilters(userFilters: UserFilter[]): void {
    this._savedFilters.next(userFilters);
  }

  /**
   * Converts lists of UserSearchFilterResponse from backend into a list of UserFilters
   * @param userSearchFilterResponse UserSearchFilterResponse received from backend
   * @returns Normalized data in the form of a list of UserFilter
   */
  buildUserFilters(userSearchFilterResponse: UserSearchFilterResponse[]): UserFilter[] {
    const result: UserFilter[] = [];
    if (userSearchFilterResponse) {
      for (const response of userSearchFilterResponse) {
        const userFilterPreference: UserFilterPreference = {
          userPrefId: response.userPrefId,
          category: response.category,
          categoryValue: response.prefValue
        };
        let userFilter: UserFilter | undefined = result.find(uf => uf.userFilterId === response.userFilterId);
        if (!userFilter) {
          userFilter = {
            email: response.email,
            filterName: response.filterName,
            modifiedTs: response.modifiedTs,
            userFilterId: response.userFilterId,
            userFilterPreferences: [],
            show: true
          };
          result.push(userFilter);
        }
        userFilter.userFilterPreferences.push(userFilterPreference);

        // Make sure we have the latest modified date
        if (userFilter.modifiedTs < response.modifiedTs) {
          userFilter.modifiedTs = response.modifiedTs;
        }
      }

      // Most recently modified filter should be first
      result.sort((a, b) => a.modifiedTs > b.modifiedTs ? -1 : 1);
    }

    return result;
  }

}

import { AfterViewInit, Component, ComponentRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { PreviewContentService } from '../../services/preview-content.service';

@Component({
  selector: 'rpc-preview-content',
  templateUrl: './preview-content.component.html',
  styleUrls: ['./preview-content.component.scss']
})
export class PreviewContentComponent implements AfterViewInit {

  @ViewChild("previewContentRef", { read: ViewContainerRef }) vcr!: ViewContainerRef;

  constructor(private previewContentService: PreviewContentService) { }

  ngAfterViewInit(): void {
    // Give the service a reference to the ng-template within the html
    this.previewContentService.previewContentContainerRef = this.vcr;
  }
}

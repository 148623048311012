<div mat-dialog-title class="dialog-title font-normal">Filter Name</div>
<div mat-dialog-content>
  <input matInput class="save-input font-normal" [(ngModel)]="data.filterName" [attr.maxLength]="200"
    placeholder="Enter Filter Name" (keyup.enter)="isSaveDisabled() ? '' : save()">
</div>
<div class="dialog-button-left font-normal">
  <div mat-dialog-actions>
    <div mat-dialog-close>Cancel</div>
  </div>
</div>
<div class="dialog-button-right font-normal ">
  <div mat-dialog-actions>
    <div  (click)="isSaveDisabled() ? '' : save()" [ngClass]="isSaveDisabled()? 'click-disable' : '' ">Save & Apply</div>
  </div>
</div>
export class RuntimeConfig {
    constructor(
        public production: boolean,
        public envName: string,
        public clientId: string,
        public authority: string,
        public redirectUri: string,
        public rpcBackendUri: string,
        public graphUri: string,
        public cacheLocation: string,
        public ptraxServer: string,
        public protectedResourceMap: Map<string, string[]>,
        public plmUrl:string
    ) { }

    get rpcBackendUriV1(): string {
        return this.rpcBackendUri + 'v1/';
    }

    get rpcBackendUriV2(): string {
        return this.rpcBackendUri + 'v2/';
    }

    get rpcBackendUriV3(): string {
        return this.rpcBackendUri + 'v3/';
    }

    get rpcBackendElasticSearchUriV1(): string {
        return this.rpcBackendUri + 'as/v1/';
    }

    get rpcBackendElasticSearchUriV2(): string {
        return this.rpcBackendUri + 'as/v2/';
    }

    get rpcBackendElasticSearchUriV3(): string {
        return this.rpcBackendUri + 'as/v3/';
    }
}
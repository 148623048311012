<div *ngIf="categorization && categorization.length >0; else empty" class ="padding-left-2-percent">
    <ol *ngFor="let path of categorization">
      {{path}}
    </ol>
</div>

<ng-template #empty>
  <span class="padding-left-2-percent">
       No results found
  </span>
</ng-template>


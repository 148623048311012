import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MsalBroadcastService, MsalGuardConfiguration, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { DetailsService } from 'src/app/common/services/details.service';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from './common/services/auth.service';
import *  as packageJson from 'package.json';
import { LoadingService } from './common/services/loading.service';

@Component({
  selector: 'rpc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  isIframe = false;
  loginDisplay: boolean = false;
  displayName?: string;
  isLoading: boolean = true;
  isUserAuthorized: boolean = false;
  faBars = faBars;
  appVersion = packageJson?.version;
  showTabletSideMenu: boolean = false;
  displayWelcome: boolean = true;
  loading$ !: Observable<boolean>;

  private readonly DESTROYING$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private broadcastService: MsalBroadcastService,
    private authService: AuthService,
    private router: Router,
    private detailsService: DetailsService,
    private loadingService: LoadingService) {
    this.loading$ = this.loadingService.loadingSub$;
  }

  ngOnInit() {
    this.isIframe = window !== window.parent && !window.opener;
    this.broadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this.DESTROYING$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
      });

    this.detailsService.getUserGroup().subscribe(res => {
      if (res.body !== null && !res.body['hasGroupName']) {
        this.router.navigate(['/not-authorized-user-group'], { skipLocationChange: false });
      } else {
        this.isUserAuthorized = true;
      }
      this.isLoading = false;
    }, (error: any) => {
      this.router.navigate(['/unhandled-error'], { skipLocationChange: false });
      this.isLoading = false;
    });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event?.url === "/" || event?.url?.includes('/home')) {
          this.displayWelcome = true;
        } else {
          this.displayWelcome = false;
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.DESTROYING$.next(undefined);
    this.DESTROYING$.complete();
  }

  navigateToHome() {
    if (this.isUserAuthorized) {
      this.router.navigate(['home']);
    }
  }

  login() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  logout() {
    this.authService.logout();
  }

  setLoginDisplay() {
    this.loginDisplay = !!this.authService.getUserAccountInfo();
    if (this.loginDisplay) {
      const myArray: string[] = this.authService.getUserAccountInfo()?.name?.split(',')!;
      let lastName = myArray[0];
      let firstAndMiddleName = myArray[1];
      let credentials = "";
      for (let i = 2; i < myArray.length; i++) {
        if (credentials.trim().length === 0) {
          credentials = myArray[i];
        }
        else {
          credentials = credentials + "," + myArray[i];
        }
      }
      if (credentials.trim().length === 0) {
        this.displayName = firstAndMiddleName + " " + lastName;
      } else {
        this.displayName = firstAndMiddleName + " " + lastName + "," + credentials;
      }
    }
  }

  displayBanner(): boolean {
    return this.displayWelcome;
  }

  handleHamburgerIconClicked(): void {
    this.showTabletSideMenu = true;
  }
}

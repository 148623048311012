<div class="preview-component">
    <div [ngClass]="{'hide-search-criteria':!showResults() , 'show-search-criteria': showResults()  }">
        <div class="search-criteria">
            <span class="area-of-interest">{{getAreaOfInterest()}}
                <a *ngIf="getAreaOfInterest().length > aoiCharLimit" (click)="showMoreAOI = !showMoreAOI"> <span
                        class="show-more-or-less">Show {{showMoreAOI ? 'less' : 'more'}}</span></a>
            </span>
            <br />
            <span class="other-criteria">{{getOtherSearchCriteria()}}
                <a *ngIf="getOtherSearchCriteria().length > searchCriteriaCharLimit"
                    (click)="showMoreQuery = !showMoreQuery"> <span class="show-more-or-less">Show {{showMoreQuery ?
                        'less' : 'more'}}</span></a>
            </span>
        </div>

        <div class="results-table-div">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z2 results-table" multiTemplateDataRows>
                <ng-container matColumnDef="protocolId">
                    <th mat-header-cell *matHeaderCellDef>Protocol/IRB Number</th>
                    <td mat-cell *matCellDef="let protocol">
                      <span>{{protocol.protocol_number}}</span>
                      <span *ngIf="protocol.mayo_irb_number">&nbsp;/&nbsp;<br />{{protocol.mayo_irb_number}}</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="protocolName">
                    <th mat-header-cell *matHeaderCellDef>Protocol Name</th>
                    <td mat-cell *matCellDef="let protocol">
                        {{protocol.study_title}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="studyType">
                    <th mat-header-cell *matHeaderCellDef>Study Type</th>
                    <td mat-cell *matCellDef="let protocol">
                        {{protocol.study_type}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="sites">
                    <th mat-header-cell *matHeaderCellDef>Locations</th>
                    <td mat-cell class="align-center-list" *matCellDef="let protocol">
                        <p *ngFor="let location of protocol.site_abbreviation">{{location}}</p>
                    </td>
                </ng-container>

                <ng-container matColumnDef="statuses">
                    <th mat-header-cell *matHeaderCellDef>Statuses</th>
                    <td mat-cell class="align-center-list" *matCellDef="let protocol">
                        <p *ngFor="let status of protocol.actual_status">{{status}}</p>
                    </td>
                </ng-container>

                <ng-container matColumnDef="phase">
                    <th mat-header-cell *matHeaderCellDef>Phase</th>
                    <td mat-cell *matCellDef="let protocol">
                        {{protocol.phase}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let protocol; let i = dataIndex">
                        <div class="link-actions">
                            <button mat-raised-button class="pi-summary-button"
                                (click)="handlePISummaryClicked(protocol, i)">PI
                                Summary</button>
                            <a class="protocol-details-link"
                                (click)="handleViewProtocol(protocol)">View&nbsp;Protocol</a>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="tabletProtocolName">
                    <td mat-cell *matCellDef="let protocol" [attr.colspan]="getColumnsToDisplay().length">
                        {{protocol.study_title}}
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="getColumnsToDisplay()"></tr>
                <tr mat-row *matRowDef="let protocol; columns: ['tabletProtocolName'];" class="tablet-protocol-name">
                </tr>
                <tr mat-row *matRowDef="let row; columns: getColumnsToDisplay();"></tr>
            </table>
        </div>
        <!-- <mat-paginator [length]="getResultsCount()" [pageSize]="getPageSize()"></mat-paginator> -->
        <rpc-paginator [length]="getResultsCount()" [pageSize]="getPageSize()"></rpc-paginator>
    </div>
    <rpc-pi-summary-preview *ngIf="showPISummary()" [study]="selectedStudy" [showNext]="showPISummaryNext()"
        [showPrevious]="showPISummaryPrevious()"
        (navigationEvent)="handlePISummaryNavigation($event)"></rpc-pi-summary-preview>
</div>

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import {
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG
} from '@azure/msal-angular';
import {
  InteractionType, IPublicClientApplication, PublicClientApplication
} from '@azure/msal-browser';
import { AppConfigService } from './app-config/app-config.service';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

export function msalGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read'],
    },
  };
}

export function msalInterceptorConfigFactory(): MsalInterceptorConfiguration {
  return {
    interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
    protectedResourceMap: AppConfigService.settings.protectedResourceMap
  };
}

export function msalInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: AppConfigService.settings.clientId,
      authority: AppConfigService.settings.authority,
      redirectUri: AppConfigService.settings.redirectUri
    },
    cache: {
      cacheLocation: AppConfigService.settings.cacheLocation,
      storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
    },
  });
}

@NgModule({
  providers: [],
  imports: [MsalModule],
})
export class MsalApplicationModule {
  static forRoot() {
    return {
      ngModule: MsalApplicationModule,
      providers: [
        {
          provide: MSAL_GUARD_CONFIG,
          useFactory: msalGuardConfigFactory
        },
        {
          provide: MSAL_INSTANCE,
          useFactory: msalInstanceFactory
        },
        {
          provide: MSAL_INTERCEPTOR_CONFIG,
          useFactory: msalInterceptorConfigFactory
        },
        MsalService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: MsalInterceptor,
          multi: true
        },
      ],
    };
  }
}

<div class="section-status">
  <table class="status-details">
    <tr>
      <th>Location</th>
      <th>Status</th>
      <th>Date</th>
    </tr>
    <tr *ngFor="let site of siteStatus.studySites">
      <td> {{ site.siteName }}
        <span *ngIf="isLeadSite(site.siteAbbreviation)">
          (lead site)
        </span>
      </td>
      <td [class]="mapStatusToColor(site.actualStatus)">
        {{ site.actualStatus }}
      </td>
      <td>
        Since {{ site.formatedAvailDate | date }}
      </td>
    </tr>
  </table>
</div>
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { firstValueFrom, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MessageModalService } from '../dialog/message-modal/message-modal.service';

@Injectable()
export class ErrorHttpInterceptor implements HttpInterceptor {

    private supportEmail: string = 'researchservicecenter@mayo.edu';

    constructor(private messageModalService: MessageModalService, private router: Router) {
    }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError(err => {
                if (err instanceof HttpErrorResponse) {
                    const error = err.error;
                    if (err.error instanceof ErrorEvent) {
                        // A client-side or network error occurred. Handle it accordingly.
                        this.logError('', error.message, '', error);
                    } else if (error?.statusCode && error?.message && error?.description) {
                        console.error('An error occurred:', err.error.message);
                        this.logError(error.statusCode, err.error.message, err.error.description, err);
                        firstValueFrom(this.messageModalService.openMessageModal({
                            title: 'An error occurred',
                            message: 'Looks like we did not anticipate this scenario, sorry about that! '
                                + 'Can you please refresh the page and try again? '
                                + 'If the error persists, please take a screenshot of entire page and contact support '
                                + `<a href="mailto:${this.supportEmail}?subject=Error Occurred in Research Protocol Catalog ${this.buildUTCTimestamp()}">${this.supportEmail}</a> </br></br>`
                                + this.buildUTCTimestamp(),
                            containsHTML: true
                        }));
                    } else {
                        this.logError(err.status, err.error, '', err);
                    }
                }
                return throwError(() => err);
            })
        );
    }

    private logError(statusCode: string | number, errorMessage: string, errorDescription: string, object?: any): void {
        const message: string =
            'An error occurred: ' + errorMessage
            + ' -- StatusCode: ' + statusCode
            + ' -- Description: ' + errorDescription;
        if (object) {
            console.error(message, object);
        }
        else {
            console.error(message);
        }
    }

    private buildUTCTimestamp(): string {
        const date = new Date();
        return `${date.getUTCMonth() + 1}/${date.getUTCDate()}/${date.getUTCFullYear()} ${date.getUTCHours()}:${date.getUTCMinutes()}:${date.getUTCSeconds()} UTC`;
    }
}

import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { SearchRequest } from 'src/app/common/models/search-request';

@Component({
  selector: 'rpc-save-filter-dialog',
  templateUrl: './save-filter-dialog.component.html',
  styleUrls: ['./save-filter-dialog.component.scss']
})
export class SaveFilterDialogComponent { //implements OnInit {

  constructor(private dialogRef: MatDialogRef<SaveFilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  /**
   * Apply save action
   */
  save() {
    this.dialogRef.close(true);
  }

  /**
   * 
   * @returns Checks save button is disable
   */
  isSaveDisabled() {
    return !(this.data.filterName && this.data.filterName.trim() != '');
  }


}

<div class="tab-section-margin">

    <div *ngIf="(funding$ | async)?.length === 0; else displayResults">
        <span class="font-12px padding-left-2-percent">
            No results found
        </span>
    </div>

    <ng-template #displayResults>
    <table >
      <thead>
        <tr>
          <th>Funding</th>
          <th>Organization</th>
        </tr>
      </thead>
      <ng-container >
        <tr *ngFor="let element of funding$ | async">
          <td class="funding">
            {{ element.fundingType }}
          </td>
          <td class="organization">
            {{ element.fundingOrg }}
          </td>
        </tr>
      </ng-container>
    </table>
    </ng-template>
  </div>
<div class="tab-section-margin">
    <div *ngIf="!notesList?.length" class="padding-left-2-percent"> No results found</div>
    <div *ngIf="notesList && notesList.length > 0">
        <ng-container *ngFor="let note of notesList">
            <span class="study-note">
                <ul>
                    <li>
                        {{ note.studyNote }}
                    </li>
                </ul>
            </span>
        </ng-container>
    </div>
</div>
<div class="details" *ngIf="umbrellaNumber">
    <div class="toggle-div">
      <a (click)="toggleSection()">
        <span *ngIf="!isAllExpanded(sections)"> Open all</span>
        <span *ngIf="isAllExpanded(sections)"> Collapse all</span>
      </a>
    </div>
  
    <mat-accordion multi="true">
      <mat-expansion-panel [(expanded)]="section.isExpanded" *ngFor="let section of sections">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ section.title }}
          </mat-panel-title>
        </mat-expansion-panel-header>
  
        <div *ngIf="section.id === 1">
            <rpc-protocol-documents [uNumber]="umbrellaNumber"></rpc-protocol-documents>
          </div>
          <div *ngIf="section.id === 2">
            <rpc-other-identifiers [uNumber]="umbrellaNumber"></rpc-other-identifiers>
          </div>
          <div *ngIf="section.id === 3">
            <rpc-funding [uNumber]="umbrellaNumber"></rpc-funding>
          </div>
          <div *ngIf="section.id === 4">
            <rpc-responsibilities [uNumber]="umbrellaNumber"></rpc-responsibilities>
          </div>
          <div *ngIf="section.id === 5">
            <rpc-notes [uNumber]="umbrellaNumber"></rpc-notes>
          </div>
  
      </mat-expansion-panel>
    </mat-accordion>
  </div>
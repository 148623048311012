<div class="tab-section-margin">
    <div *ngIf="(otherIdentifiers$ | async)?.length === 0; else displayResults">
        <span class="font-12px padding-left-2-percent">
            No results found
        </span>
    </div>

    <ng-template #displayResults>

        <table>
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Type of ID</th>
                    <th>Assigning Organizations</th>
                </tr>
            </thead>
            <ng-container >
                <tr *ngFor="let element of otherIdentifiers$ | async">
                    <td>
                        {{ element.referenceNumber }}
                    </td>
                    <td>
                        {{ element.referenceType }}
                    </td>
                    <td>
                        {{ element.assigningOrg }}
                    </td>
                </tr>
            </ng-container>

        </table>

    </ng-template>

</div>
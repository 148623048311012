<div class="org-resp">
    <div *ngIf="!orgResponsibilities" class="font-12px padding-left-2-percent">No results found</div>
    <div *ngIf="orgResponsibilities">
        <table>
            <tr>
                <th>Organization</th>
                <th>Responsibility</th>
            </tr>
            <tr *ngIf="orgResponsibilities.sponsor">
                <td>{{ orgResponsibilities.sponsor }}</td>
                <td>Sponsor</td>
            </tr>
            <tr *ngIf="orgResponsibilities.dataCenter">
                <td>{{ orgResponsibilities.dataCenter }}</td>
                <td>Data Center</td>
            </tr>
            <tr *ngIf="orgResponsibilities.randomCenter">
                <td>{{ orgResponsibilities.randomCenter }}</td>
                <td>Random Center</td>
            </tr>
            <ng-container *ngFor="let clinic of orgResponsibilities.clinicalCoordinationCenter">
                <tr *ngIf="clinic">
                    <td>{{ clinic }}</td>
                    <td>Clinical Coordination Center</td>
                </tr>
            </ng-container>
        </table>
    </div>
</div>
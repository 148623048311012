import { LoadingService } from './../../common/services/loading.service';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, NavigationStart, Router } from '@angular/router';
import { Subscription, firstValueFrom } from 'rxjs';
import { SearchRequest } from 'src/app/common/models/search-request';
import { StudyTitle } from 'src/app/common/models/study-title';
import { DetailsService } from 'src/app/common/services/details.service';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { SearchService } from 'src/app/common/services/search.service';
import { PreviewContentService } from 'src/app/common/services/preview-content.service';

@Component({
  selector: 'rpc-protocol-details',
  templateUrl: './protocol-details.component.html',
  styleUrls: ['./protocol-details.component.scss']
})
export class ProtocolDetailsComponent implements OnDestroy {

  umbrellaNumber!: string;
  study!: StudyTitle;

  isDetailsBookMarked : boolean = false;

  isFromPISummary : boolean = false;

  faAngleLeft = faAngleLeft;

  searchCriteria: SearchRequest = {
    areaOfInterest: [],
    irbProtocol: [],
    status: [],
    location: [],
    phase: [],
    gender: [],
    population: [],
    additionalFilters: [],
    diseaseGroupCategories: [],
    studyType: [],
    sponsor: [],
    page: 0
  };


  tabs = [
    {
      label: 'Details',
      route: 'detail'
    },
    {
      label: 'Contacts',
      route: 'contact'
    },
    {
      label: 'More Info',
      route: 'more-info'
    }

  ];

  private subscription!: Subscription;

  constructor(private route: ActivatedRoute, private router: Router, private detailsService: DetailsService, private searchService: SearchService, 
    private loadingService: LoadingService,
    private previewContentService : PreviewContentService) {
    this.umbrellaNumber = this.route.snapshot?.params['uNumber'];
    this.subscription = this.detailsService
      .getStudyTitle(this.umbrellaNumber)
      .subscribe((res) => {
        this.study = res;
        this.detailsService.studyTitle = res;
      });
      
      firstValueFrom(searchService.lastSearchRequest).then(value => {
        if(!value){
          this.isDetailsBookMarked = true;
        }
      })

      this.previewContentService.fromPISummary.subscribe((value) => {
          this.isFromPISummary = value;
      });
      
    }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  /**
   * Handle back to protocol results
   */
  backToSearchResults(): void {
    this.loadingService.setIsSearchApiCall(true);
    this.router.navigate(['/home']);
    this.previewContentService.setRouteToPISummary(null);
  }

   /**
   * Handle back to PI Summary
   */
   backToPISummary(): void {
    this.router.navigate(['/home']);
    this.previewContentService.setRouteToPISummary(this.study.protocol);
  }

}

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { SectionType } from 'src/app/common/models/section-type';
import { DetailsService } from 'src/app/common/services/details.service';
import { MatExpansionComponent } from '../mat-expansion.component';

@Component({
  selector: 'rpc-more-info',
  templateUrl: './more-info-parent.component.html',
  styleUrls: ['./more-info-parent.component.scss']
})
export class MoreInfoParentComponent extends MatExpansionComponent implements OnInit, OnDestroy {
  umbrellaNumber!: string;
  subscriptionList: Subscription[] = [];

  sections: SectionType[] = [
    {
      id: 1,
      title: 'Protocol Documents',
      description: '',
      isExpanded:true
    },
    {
      id: 2,
      title: 'Other Identifiers',
      description: 'Other Identifiers for this study',
      isExpanded:true
    },
    {
      id: 3,
      title: 'Funding',
      description: 'Funding for this study',
      isExpanded:true
    },
    {
      id: 4,
      title: 'Responsibilities',
      description: 'Org responsibilities for this study',
      isExpanded:true
    },
    {
      id: 5,
      title: 'Notes',
      description: 'Note for this study',
      isExpanded:true
    },
  ];

  constructor(
    public activatedRoute: ActivatedRoute,
    private detailsService: DetailsService
  ) {
    super();
    this.umbrellaNumber = this.activatedRoute.parent?.snapshot.params['uNumber'];
  }

  ngOnInit(): void {
    this.subscriptionList.push(
    this.detailsService.isCancerRelatedStudy(this.umbrellaNumber).subscribe(resp => {
      if(!resp){
        this.sections.forEach((section,index)=>{
          if(section.id==1) this.sections.splice(index,1);
       });
      }
    })
    );
    
  }

  ngOnDestroy(): void {
    this.subscriptionList.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { MatPaginatorDefaultOptions, MatPaginatorIntl, MAT_PAGINATOR_DEFAULT_OPTIONS, MatPaginator } from '@angular/material/paginator';
import { faAngleLeft, faAngleRight, faEllipsis } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'rpc-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],

  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class PaginatorComponent extends MatPaginator {
  /**
   * Implementation based off of:
   * https://blog.angular-university.io/angular-material-data-table/
   * https://stackoverflow.com/questions/70787342/how-can-i-style-mat-paginator-angular-as-shown
   * https://github.com/angular/components/blob/c5cede87c3610b090154f7c7b55cc536b043cc1d/src/material/paginator/paginator.ts#L97
   */

  faAngleLeft = faAngleLeft;
  faAngleRight = faAngleRight;

  constructor(
    international: MatPaginatorIntl,
    changeDetectorRef: ChangeDetectorRef,
    @Optional() @Inject(MAT_PAGINATOR_DEFAULT_OPTIONS) defaults?: MatPaginatorDefaultOptions
  ) {
    super(international, changeDetectorRef, defaults);
  }

  /**
   * Sets the paginator to the given page number
   * @param newPage New page number 
   */
  setPageIndex(newPage: number): void {
    if (newPage >= 0) {
      const prevIndex: number = this.pageIndex;
      this.pageIndex = newPage;
      this.page.emit({
        pageIndex: this.pageIndex,
        length: this.length,
        pageSize: this.pageSize,
        previousPageIndex: prevIndex
      });
    }
  }

  /**
   * Figures out which page numbers and location of ellipsis that should be shown on the UI
   * Note: should be able to handle any number of items >= 5
   * Remember: pageIndex is zero based, while display is 1 based
   * @returns an array of numbers and ellipsis to show the page numbers available
   */
  getPageNumbers(): string[] {
    const numPages: number = this.getNumberOfPages();
    const numDisplayItems: number = 7; // This should be an odd number >= 5
    const ellipsis: string = '...';
    const numItemsAccountedFor = 3; // '1', 'numPages', '...'
    const numItemsToFill = numDisplayItems - numItemsAccountedFor;
    const adjustedPageIndex = this.pageIndex + 1;

    // ['1', '2', '3', '4', '5', '6', '7']
    // ['1', '2']
    if (numPages <= numDisplayItems) {
      return Array(numPages).fill('').map((x, i) => (i + 1).toString());
    }
    else {
      let array: string[] = Array(numDisplayItems);
      array[0] = '1';
      array[numDisplayItems - 1] = numPages.toString();

      // ['1', '2', '3', '4', '5', '...', '25']
      if (this.pageIndex < numItemsToFill) {
        for (let index = 1; index <= numItemsToFill; index++) {
          array[index] = (index + 1).toString();
        }
        array[numDisplayItems - 2] = ellipsis;
      }

      // ['1', '...', '21', '22', '23', '24', '25']
      else if (numPages - this.pageIndex < numItemsToFill) {
        array[1] = ellipsis;
        for (let index = numItemsToFill; index >= 1; index--) {
          array[numDisplayItems - 1 - index] = (numPages - index).toString();
        }
      }

      // ['1', '...', '15', '16', '17', '...', '25']
      // ['1', '...', '4', '5', '6', '...', '25']
      // ['1', '...', '20', '21', '22', '...', '25']
      else {
        array[1] = ellipsis;
        array[numDisplayItems - 2] = ellipsis;
        const middle = Math.floor(numDisplayItems / 2);
        array[middle] = adjustedPageIndex.toString();
        for (let i = 2; i < middle; i++) {
          array[i] = (adjustedPageIndex - i + 1).toString();
          array[numDisplayItems - 1 - i] = (adjustedPageIndex + i - 1).toString();
        }
      }

      return array;
    }
  }

}

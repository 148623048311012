<div class="key-contacts">
    <span>Key Contacts</span>
    <table class="contact-details">
        <tr>
            <th>Location</th>
            <th>Role</th>
            <th>Primary Contact(s)</th>
            <th>Phone Number(s)</th>
        </tr>
        <tr *ngFor="let person of persons">
            <td>  {{ person?.location }}</td>
            <td> {{ person?.roleDescription}}</td>
            <td>
                <a *ngIf="person.email" href="mailto:{{ person.email }}?subject={{emailSubject}}"> 
                    {{ person.name }}
                </a>
                <a *ngIf="person.email === undefined"> {{ person.name }}</a>
            </td>
            <td>
                {{ person.phone }}
            </td>
        </tr>
    </table>
</div>
<div *ngIf="accrual; else dataNotFound" class="accural-table">
  <table>
    <tr>
      <th></th>
      <th>Accruals</th>
      <th>Screen Failures</th>
      <th>Total Screens</th>
    </tr>
    <tr class="irb-style">
      <td>Mayo IRB Approved</td>
      <td class="number">{{ accrual.mayoIrbApproved.accruals }}</td>
      <td class="number">{{ accrual.mayoIrbApproved.screenFailures }}</td>
      <td class="number">{{ accrual.mayoIrbApproved.screens }}</td>
    </tr>
    <tr class="irb-style">
      <td>Actuals</td>
      <td class="number">{{ accrual.actualAccrual.accruals }}</td>
      <td class="number">{{ accrual.actualAccrual.screenFailures }}</td>
      <td class="number">{{ accrual.actualAccrual.screens }}</td>
    </tr>
    <tr>
      <td class="spacer" colspan="4"></td>
    </tr>

    <ng-container *ngFor="let site of accrual.siteAccruals">
      <tr>
        <td>
          <span *ngIf="site.name" >
            {{ this.findSiteName(site.name) }}
          </span>
        </td>
        <td class="number">{{ site.accruals }}</td>
        <td class="number">{{ site.screenFailures }}</td>
      </tr>
    </ng-container>
  </table>
</div>
<ng-template #dataNotFound>
  <span class="padding-left-2-percent">
      No results found 
  </span>
</ng-template>
import { SearchRequest } from 'src/app/common/models/search-request';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { SearchResultsDataSource } from '../../models/search-results-data-source';
import { UserFilter } from '../../models/user-filter';
import { UserFilterPreference } from '../../models/user-filter-preference';
import { UserSearchFilterResponse } from '../../models/user-search-filter-response';
import { AuthService } from '../../services/auth.service';
import { SearchService } from '../../services/search.service';
import { UserSearchPreferenceService } from '../../services/user-search-preference.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/dialog/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'rpc-saved-filters',
  templateUrl: './saved-filters.component.html',
  styleUrls: ['./saved-filters.component.scss'],
  animations: [
    /**
     * Reference
     * https://stackblitz.com/edit/angular-animation-when-removing?file=src%2Fapp%2Fapp.component.ts
     */
    // the fade-in/fade-out animation.
    trigger('simpleFadeAnimation', [
      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({ opacity: 1 })),

      // fade in when created.
      transition(':enter', [style({ opacity: 0 }), animate(1000)]),

      // fade out when destroyed.
      transition(':leave', animate(1000, style({ opacity: 0 }))),
    ]),
  ],
})
export class SavedFiltersComponent implements OnInit, OnDestroy {

  @Output() hideSideMenu: EventEmitter<any> = new EventEmitter();
  savedFilters: UserFilter[] = [];
  faX = faX;

  private subscriptions: Subscription[] = [];
  constructor(private userSearchPreferenceService: UserSearchPreferenceService,
    private authService: AuthService,
    private router: Router,
    private searchService: SearchService,
    public dialog: MatDialog) {
    router.onSameUrlNavigation = 'ignore';
  }

  ngOnInit() {
    this.getUserFilters();
  }

  public getUserFilters(): void {
      this.subscriptions.push(
        this.userSearchPreferenceService.getUserSearchFilters()
          .subscribe((result: UserSearchFilterResponse[]) => {
            this.savedFilters = this.userSearchPreferenceService.buildUserFilters(result);
            this.userSearchPreferenceService.setSelectedPreference(this.savedFilters[0]);
            this.userSearchPreferenceService.setSavedFilters(this.savedFilters);
            this.userSearchPreferenceService.savedFilters.subscribe((savedFilters) => { this.savedFilters = savedFilters; });
          })
      );
  }

  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  /**
   * Handle saved filter click
   * @param filter
   */
  handleFilter(filter: UserFilter) {
    this.searchService.resetLastSearchRequest();
    this.userSearchPreferenceService.setSelectedPreference(filter);
    this.hideSideMenu.emit();
    this.router.navigate(['/home']);
  }

  /**
   * Delete Filter
   * @param index
   * @param userFilter
   */

  deleteFilter(index: number, userFilter: UserFilter) {

    //create confirmation modal

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.backdropClass = 'modal-backdrop';
    dialogConfig.data = {
      title: "Delete Filter",
      content: `Are you sure you want to permanently delete the ${userFilter.filterName} filter?`
    };

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
    this.subscriptions.push(
      this.userSearchPreferenceService.deleteUserFilter(userFilter.userFilterId).subscribe((result) => {
        this.savedFilters.splice(index, 1);

        const emptyFilter: UserFilter = {
          email: '',
          filterName: '',
          modifiedTs: '',
          userFilterId: -1,
          userFilterPreferences: [{
            "userPrefId": -1,
            "category": "Status",
            "categoryValue": "Accruing"
          },{
            "userPrefId": -1,
            "category": "Study Type",
            "categoryValue": "Interventional",
          },{
            "userPrefId": -1,
            "category": "Population",
            "categoryValue": "All",
          }],
          show: false
        };
        this.userSearchPreferenceService.setSelectedPreference(emptyFilter);

              // Check if current route is already the homepage
          const isHomePage = this.router.url.includes('/home');
          if (!isHomePage) {
                  // Redirect to the homepage
          this.router.navigate(['/home'], { queryParams : {deleteFilter: true}});

          } //close sidemenu
          this.hideSideMenu.emit();
      })
    );


      } else {
        // when cancel is clicked
      }
    });

  }

}

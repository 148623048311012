import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Observable, Subject, debounceTime, distinctUntilChanged, firstValueFrom, switchMap, from, of, Subscription } from 'rxjs';
import { Node } from '../node-tree/node-tree.service';
import { UserSearchPreferenceService } from '../../services/user-search-preference.service';

@Component({
  selector: 'rpc-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent implements OnInit, OnDestroy, OnChanges {
  @Input() value: string | null = null;
  @Input() placeholder: string = "";
  @Input() labelName: string = "";
  @Input() autocompleteSuggestions: string[] = [];
  @Input() requireSuggestion: boolean = false; // Indicates if any chips created must come from suggestions
  @Input() getSuggestionsFunction?: (textValue: string) => Observable<string[]>;
  @Input() treeNodes: Node[] | null = null;
  @Input() selectedTreeNodes: Node[] = [];
  @Input() displayRootTreeNode: boolean = true;
  @Output() suggestionSelectionChange = new EventEmitter<string>();
  @Output() treeNodesSelectedChange = new EventEmitter<Node[]>();
  @Output() autocompleteSuggestionsChange = new EventEmitter<string[]>();
  @Input() shouldUpdateAutoSuggestionOnClick: boolean = false;

  @ViewChild('auto', { static: false }) matAutoComplete!: MatAutocomplete;
  @ViewChild('input', { static: false }) textInput!: ElementRef<HTMLInputElement>;

  inputValue: Subject<string> = new Subject<string>();
  inputControl = new FormControl();
  userSearchPreference: Subscription;

  constructor(private userSearchPreferenceService: UserSearchPreferenceService) {
    this.userSearchPreference = userSearchPreferenceService.clearSearch.subscribe(value => {
      if (value) {
        this.clearValue();
      }
    });
  }

  ngOnInit() {
    this.inputValue.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap<string, Observable<any>>((val) => {
        if (!this.showTreeView() && this.getSuggestionsFunction) {
          return from(firstValueFrom(this.getSuggestionsFunction(val)));
        }
        return of();
      })
    ).subscribe(newValue => {
      this.autocompleteSuggestions = newValue;
      this.autocompleteSuggestionsChange.emit(this.autocompleteSuggestions);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['value']) {
      this.inputControl.setValue(this.value);
    }
  }

  ngOnDestroy(): void {
    this.inputValue.unsubscribe();
    this.userSearchPreference.unsubscribe();
  }

  clearValue(): void {
    if (this.textInput) {
      this.textInput.nativeElement.value = '';
      this.inputControl.setValue(null);
      this.inputValue.next('');
    }
  }

  handleClickEvent(): void {
    if (this.shouldUpdateAutoSuggestionOnClick) {
      this.inputValue.pipe(
        switchMap<string, Observable<any>>((val) => {
          if (!this.showTreeView() && this.getSuggestionsFunction) {
            return from(firstValueFrom(this.getSuggestionsFunction(val)));
          }
          return of();
        })
      ).subscribe(newValue => {
        this.autocompleteSuggestions = newValue;
      });
    }
  }

  /**
   * Gets the new value and emits the change to the parent component
   * @param event Input changed event
   */
  handleInputChanged(event: Event): void {
    const value = (event.target as HTMLTextAreaElement).value;
    if (!this.inputValue) {
      this.autocompleteSuggestions = [];
    }
    this.inputValue.next(value);
  }

  handleSelectionChanged(event: MatAutocompleteSelectedEvent): void {
    this.suggestionSelectionChange.emit(event.option.value);
  }

  /**
   * Handles focus for input box and triggers a search for suggestions
   * @param event focus event
   */
  handleOnFocus(event: Event): void {
    this.inputValue.next(this.inputControl.value);
  }

  /**
   * Selects the first option in the Autocomplete if the key was 'Enter'
   * @param event keyboard key up event
   */
  handleInputKeyUp(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      if (!this.requireSuggestion) {
        this.suggestionSelectionChange.emit(this.inputControl.value);
        this.clearValue();
      }
      else if (this.matAutoComplete.options?.first) {
        this.matAutoComplete.options?.first?.select();
      }
    }
  }

  showTreeView(): boolean {
    return !!this.treeNodes;
  }

  /**
   * Emits the selected nodes and updates the display values for the nodes
   * @param nodesSelected selected nodes from the tree component
   */
  handleTreeNodesSelectionChanged(nodesSelected: Node[]): void {
    this.treeNodesSelectedChange.emit(nodesSelected);
  }

}

import { Location, LocationStrategy } from '@angular/common';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo, EndSessionRequest, RedirectRequest } from '@azure/msal-browser';
import { AppConfigService } from 'src/app/app-config/app-config.service';

@Injectable({
  providedIn: 'root'
})
/**
 * Wrapper for MSAL Auth Service
 */
export class AuthService {

  constructor(private msalAuthService: MsalService) {
  }

  /**
   * Gets the account information for the active user
   * @returns account information for the currently logged in user
   */
  getUserAccountInfo(): AccountInfo | null {
    return this.getActiveAccount();
  }

  loginRedirect(request?: RedirectRequest) {
    return this.msalAuthService.loginRedirect(request);
  }

  logout() {
    return this.msalAuthService.logoutRedirect({
      postLogoutRedirectUri: AppConfigService.settings?.redirectUri
    });
  }

  private getActiveAccount(): AccountInfo | null {
    let currentAccount = this.msalAuthService.instance.getActiveAccount();
    if (!currentAccount && this.msalAuthService.instance.getAllAccounts()?.length > 0) {
      currentAccount = this.msalAuthService.instance.getAllAccounts()[0];
      this.msalAuthService.instance.setActiveAccount(currentAccount);
    }
    return currentAccount;
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { SearchRequest } from 'src/app/common/models/search-request';
import { SearchResult } from 'src/app/common/models/search-result';
import { PreviewContentService } from 'src/app/common/services/preview-content.service';

export enum NavigationOptions {
  Back = 1,
  Previous = 2,
  Next = 3
}

@Component({
  selector: 'rpc-pi-summary-preview',
  templateUrl: './pi-summary-preview.component.html',
  styleUrls: ['./pi-summary-preview.component.scss']
})
export class PISummaryPreviewComponent {

  @Input() study?: SearchResult;
  @Input() showPrevious: boolean = true;
  @Input() showNext: boolean = true;
  @Output() navigationEvent: EventEmitter<NavigationOptions> = new EventEmitter();

  faAngleLeft = faAngleLeft;
  faAngleRight = faAngleRight;

  constructor(private router: Router,private previewContentService : PreviewContentService) { }

  /***** Click Handlers *****/

  handleBackClicked(): void {
    this.navigationEvent.emit(NavigationOptions.Back);
  }

  handlePreviousClicked(): void {
    this.navigationEvent.emit(NavigationOptions.Previous);
  }

  handleNextClicked(): void {
    this.navigationEvent.emit(NavigationOptions.Next);
  }
  
  redirectToViewProtocolPage() : void {
    this.router.navigate(['/details/'+ this.study?.id+'/detail']);
    this.previewContentService.setFromPISummary(true);
  }

}

<div class="search-label">
    {{labelName}}
</div>
<div>
    <div class="input-wrapper">
        <input #input type="text" class="search-input" [placeholder]="placeholder" [formControl]="inputControl"
            [matAutocomplete]="showTreeView() ? tree : auto" (input)="handleInputChanged($event)"
            (click)="handleClickEvent()" (focus)="handleOnFocus($event)" (keyup)="handleInputKeyUp($event)">
        <img for="input" src="assets/icons/SearchIcon.svg" class="input-icon">
    </div>
    <mat-autocomplete #auto="matAutocomplete" class="search-input" aria-label="autocomplete options"
        (optionSelected)="handleSelectionChanged($event)">
        <mat-option *ngFor="let option of autocompleteSuggestions" [value]="option">
            {{option}}
        </mat-option>
    </mat-autocomplete>

    <!-- Tree Autocomplete -->
    <mat-autocomplete #tree="matAutocomplete" class="search-tree" aria-label="autocomplete tree options">
        <!-- mat-option here only because the tree won't display without it. -->
        <mat-option disabled class="tree-option-header"></mat-option>
        <rpc-node-tree [nodeTree]="treeNodes ?? []" [filterText]="inputValue | async"
            [selectedNodes]="selectedTreeNodes"
            (nodesSelectedChange)="handleTreeNodesSelectionChanged($event)"></rpc-node-tree>
    </mat-autocomplete>
</div>
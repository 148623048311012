import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, firstValueFrom, tap } from 'rxjs';
import { PaginatorComponent } from 'src/app/common/controls/paginator/paginator.component';
import { SearchRequest } from 'src/app/common/models/search-request';
import { SearchResponse } from 'src/app/common/models/search-response';
import { SearchResult } from 'src/app/common/models/search-result';
import { SearchResultsDataSource } from 'src/app/common/models/search-results-data-source';
import { PreviewContentService } from 'src/app/common/services/preview-content.service';
import { SearchService } from 'src/app/common/services/search.service';
import { NavigationOptions } from '../pi-summary-preview/pi-summary-preview.component';
import { LoadingService } from 'src/app/common/services/loading.service';

@Component({
  selector: 'rpc-search-results-preview',
  templateUrl: './search-results-preview.component.html',
  styleUrls: ['./search-results-preview.component.scss']
})
export class SearchResultsPreviewComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() dataSource!: SearchResultsDataSource;
  @Input() searchResponse?: SearchResponse;

  @ViewChild(PaginatorComponent) paginator!: PaginatorComponent;

  selectedStudy?: SearchResult;
  selectedStudyIndex: number = -1;

  searchResults: SearchResult[] = [];
  searchIndex: number = 0;
  routeToPISummaryProtocol : string | null = null;

  searchCriteria: SearchRequest | null = null;

  showMoreAOI: boolean = false;
  showMoreQuery: boolean = false;
  aoiCharLimit = 100;
  searchCriteriaCharLimit = 200;
  private AREA_OF_INTEREST_SEPARATOR = ' or ';
  private OTHER_CRITERIA_SEPARATOR: string = ', ';
  private subscriptions: Subscription[] = [];

  constructor(private previewContentService: PreviewContentService,
    private router: Router,
    private searchService: SearchService,
    private loadingService: LoadingService) {
    this.setPreviewPaneTitles();
   
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.dataSource.searchResponse$
        .subscribe({
          next: (searchResponse: SearchResponse) => {
            this.handleDataUpdated(searchResponse);
            this.setPreviewPaneTitles();
            this.selectedStudy = undefined;
            if(this.routeToPISummaryProtocol){
              this.searchResults = this.searchResponse?.results!;
              this.searchIndex = this.searchResults?.findIndex(item => item.protocol_number === this.routeToPISummaryProtocol);
              this.handlePISummaryClicked(this.searchResults[this.searchIndex], this.searchIndex)
              this.routeToPISummaryProtocol = null;
            }
          }
        })
    );

    this.subscriptions.push(
      this.searchService.lastSearchRequest
        .subscribe((searchRequest: SearchRequest | null) => {
          this.searchCriteria = searchRequest;
        })
    );
    this.previewContentService.routeToPISummary.subscribe((value) => {
      this.routeToPISummaryProtocol = value;
    });
  }

  ngAfterViewInit(): void {
    this.subscriptions.push(
      this.paginator?.page
        .pipe(
          tap(() => {
            this.loadResultsPage();
          })
        )
        .subscribe()
    );
  }

  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub?.unsubscribe();
    }
  }

  scrollToTop(): void {
    const tableContainer = document.querySelector('table tbody') as HTMLElement;
    tableContainer.scrollTo({
      top : 0,
      behavior :'smooth'
    })
  }

  /**
   * Runs the existing search for the new current page
   */
  loadResultsPage(): void {
    if (this.searchCriteria) {
      this.searchCriteria.page = this.paginator.pageIndex + 1;// Paginator is zero based, backend is 1 based
      this.dataSource.search(this.searchCriteria);
      this.scrollToTop();
    }
  }

  /***** Show Sections Functions ******/
  showResults(): boolean {
    return !this.selectedStudy;
  }

  showPISummary(): boolean {
    return !!this.selectedStudy;
  }

  /***** Display Formatter Functions *****/
  /**
   * Formats the selected areas of interest to be displayed
   * @returns Formatted Area of Interests
   */
  getAreaOfInterest(): string {
    if (this.searchCriteria && this.searchCriteria.areaOfInterest.length > 0) {
      let areaOfInterest = this.searchCriteria.areaOfInterest.join(this.AREA_OF_INTEREST_SEPARATOR);
      if (this.showMoreAOI) {
        return areaOfInterest;
      }
      return areaOfInterest.length > this.aoiCharLimit ? areaOfInterest.substring(0, this.aoiCharLimit) + '...' : areaOfInterest;
    }
    else if (this.getOtherSearchCriteria() === '') {
      return 'No Search Criteria Selected';
    }
    return '';
  }

  /**
   * Formats the other selected search criteria for display
   * @returns Formatted search criteria
   */
  getOtherSearchCriteria(): string {
    let searchCriteria: string = this.searchCriteria ?
      this.searchCriteria.diseaseGroupCategories
        .concat(...this.searchCriteria.irbProtocol)
        .concat(...this.searchCriteria.additionalFilters)
        .concat(...this.searchCriteria.status)
        .concat(...this.searchCriteria.phase)
        .concat(...this.searchCriteria.location)
        .concat(...this.searchCriteria.studyType)
        .concat(...this.searchCriteria.gender)
        .concat(...this.searchCriteria.population)
        .concat(...this.searchCriteria.sponsor)
        .join(this.OTHER_CRITERIA_SEPARATOR)
      : '';
    if (this.showMoreQuery) {
      return searchCriteria;
    }
    return searchCriteria.length > this.searchCriteriaCharLimit ? searchCriteria.substring(0, this.searchCriteriaCharLimit) + '...' : searchCriteria;
  }

  /**
   * Indicates to the PI Summary Component if it should show the previous button
   * @returns True if selected study isn't the first element
   */
  showPISummaryPrevious(): boolean {
    return this.selectedStudyIndex > 0;
  }

  /**
   * Indicates to the PI Summary Component if it should show the next button
   * @returns True if selected study isn't the last element
   */
  showPISummaryNext(): boolean {
    return this.searchResponse ? this.selectedStudyIndex < this.searchResponse?.results.length - 1 : false;
  }

  getResultsCount(): number {
    return this.searchResponse?.page.total_results ?? 0;
  }

  getPageSize(): number {
    return this.searchResponse?.page.size ?? 20;
  }

  /**
   * Determines what columns should be displayed based on the width of the window
   * @returns array with the column definitions
   */
  getColumnsToDisplay(): string[] {
    const widthBreakpoint: number = 992;
    const windowWidth: number = window.innerWidth;

    if (windowWidth <= widthBreakpoint) {
      return [
        'protocolId',
        'studyType',
        'phase',
        'statuses',
        'sites',
        'actions'
      ];
    }
    else {
      return [
        'protocolId',
        'protocolName',
        'studyType',
        'phase',
        'statuses',
        'sites',
        'actions'
      ];
    }
  }

  /***** Click Handlers *****/
  handlePISummaryClicked(study: SearchResult, index: number): void {
    this.selectedStudy = study;
    this.selectedStudyIndex = index;
    this.previewContentService.previewTitle = 'PI Summary';
    this.previewContentService.previewSubtitle = '';
    this.loadingService.setIsSearchApiCall(false);
  }

  /**
   * Handle view protocol
   * @param study
   */
  handleViewProtocol(study: SearchResult): void {
    this.router.navigate(['/details/' + study?.id + '/detail']);
    this.previewContentService.setFromPISummary(false);
  }

  /**
   * Handles navigation emitted by the PI Summary child component
   * @param event NavigationOptions enum option
   */
  handlePISummaryNavigation(event: NavigationOptions): void {
    switch (event) {
      case NavigationOptions.Back:
        this.navigatePISummaryBack();
        break;
      case NavigationOptions.Previous:
        this.navigatePISummaryPrevious();
        break;
      case NavigationOptions.Next:
        this.navigatePISummaryNext();
        break;
    }
  }

  /**
   * Changes the view from PI Summary to the Search Results view
   */
  private navigatePISummaryBack(): void {
    this.selectedStudy = undefined;
    this.selectedStudyIndex = -1;
    this.setPreviewPaneTitles();
  }

  /**
   * Updates the selected study and index to be the previous study in the list
   * Only updates if it's not the first study
   */
  private navigatePISummaryPrevious(): void {
    if (!this.showPISummaryPrevious()) {
      return;
    }

    this.handlePISummaryClicked(this.searchResponse!.results[--this.selectedStudyIndex], this.selectedStudyIndex);
  }

  /**
     * Updates the selected study and index to be the next study in the list
     * Only updates if it's not the last study
     */
  private navigatePISummaryNext(): void {
    if (!this.showPISummaryNext()) {
      return;
    }

    this.handlePISummaryClicked(this.searchResponse!.results[++this.selectedStudyIndex], this.selectedStudyIndex);
  }

  /***** Private Functions *****/
  private handleDataUpdated(searchResponse: SearchResponse): void {
    this.searchResponse = searchResponse;
    if (this.paginator) {
      this.paginator.pageIndex = searchResponse.page.current - 1;
    }
  }

  private setPreviewPaneTitles(): void {
    this.previewContentService.previewTitle = 'Protocols';
    this.previewContentService.previewSubtitle =  this.getResultsCount() !== 2000 ? '('+ this.getResultsCount() + ')' : '('+ this.getResultsCount() + '+)' ;
  }
}

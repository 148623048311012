import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, catchError, finalize, Observable, of } from 'rxjs';
import { SearchService } from '../services/search.service';
import { SearchRequest } from './search-request';
import { SearchResponse } from './search-response';
import { SearchResult } from './search-result';

export class SearchResultsDataSource implements DataSource<SearchResult> {

    // eslint-disable-next-line @typescript-eslint/naming-convention
    private defaultSearchResponse: SearchResponse = { results: [], page: { current: 1, size: 20, total_pages: 1, total_results: 0 } };

    private searchResultSubject = new BehaviorSubject<SearchResult[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    private searchResponse = new BehaviorSubject<SearchResponse>(this.defaultSearchResponse);

    get loading$(): Observable<boolean> {
        return this.loadingSubject.asObservable();
    }

    get searchResponse$(): Observable<SearchResponse> {
        return this.searchResponse.asObservable();
    }

    constructor(private searchService: SearchService) { }

    connect(collectionViewer: CollectionViewer): Observable<readonly SearchResult[]> {
        return this.searchResultSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.searchResultSubject.complete();
        this.loadingSubject.complete();
    }

    search(searchRequest: SearchRequest) {
        // The app was doing multiple searches for a single page or filter load. This is here so we can monitor the number of searches
        console.log('Searching...', searchRequest, new Date());
        if (this.isSearchRequestEmpty(searchRequest)) {
            this.searchResultSubject.next(this.defaultSearchResponse.results);
            this.searchResponse.next(this.defaultSearchResponse);
        }
        else {
            this.loadingSubject.next(true);

            this.searchService.searchProtocols(searchRequest)
                .pipe(
                    catchError(() => {
                        return of(this.defaultSearchResponse);
                    }),
                    finalize(() => this.loadingSubject.next(false))
                )
                .subscribe(response => {
                    this.searchResultSubject.next(response.results);
                    this.searchResponse.next(response);
                });
        }
    }

    /**
     * Checks if the search request object has any search parameters
     * @returns false if any fields not excluded have values
     */
    private isSearchRequestEmpty(searchRequest: SearchRequest): boolean {
        for (const key in searchRequest) {
            if (Object.prototype.hasOwnProperty.call(searchRequest, key)) {
                const element = (searchRequest as unknown as { [key: string]: string[]; })[key];
                if (element && element.length > 0) {
                    return false;
                }
            }
        }

        return true;
    }

}

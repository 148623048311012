import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatTreeFlattener, MatTreeFlatDataSource } from '@angular/material/tree';
import { Node, FlatNode } from './node-tree.service';

// https://stackblitz.com/edit/angular-material-tree-dropdown?file=src%2Fapp%2Fapp.component.ts,src%2Fapp%2Fapp.component.html
@Component({
  selector: 'rpc-node-tree',
  templateUrl: './node-tree.component.html',
  styleUrls: ['./node-tree.component.scss']
})
export class NodeTreeComponent implements OnChanges {
  @Input() nodeTree: Node[] = [];
  @Input() filterText: string | null = null;
  @Input() selectedNodes: Node[] = [];
  @Output() nodesSelectedChange = new EventEmitter<Node[]>();

  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<FlatNode, Node>();

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<Node, FlatNode>();

  /** A selected parent node to be inserted */
  selectedParent: FlatNode | null = null;

  treeControl: FlatTreeControl<FlatNode>;
  treeFlattener: MatTreeFlattener<Node, FlatNode>;
  dataSource: MatTreeFlatDataSource<Node, FlatNode>;
  /** The selection for checklist */
  checklistSelection = new SelectionModel<FlatNode>(true /* multiple */);

  // Used when bulk loading selections to prevent an emit event for every selection
  private emitSelectionsImmediately: boolean = true;

  constructor() {
    this.treeFlattener = new MatTreeFlattener(
      this.transformer,
      this.getLevel,
      this.isExpandable,
      this.getChildren
    );
    this.treeControl = new FlatTreeControl<FlatNode>(
      this.getLevel,
      this.isExpandable
    );
    this.dataSource = new MatTreeFlatDataSource(
      this.treeControl,
      this.treeFlattener
    );
  }

  ngOnChanges(simpleChange: SimpleChanges): void {
    if (simpleChange['nodeTree'] && !simpleChange['nodeTree'].firstChange) {
      this.dataSource.data = this.nodeTree;
      this.updateSelectedNodes();
    }
    if (simpleChange['filterText']) {
      this.filterChanged(this.filterText ?? '');
    }

    if (simpleChange['selectedNodes'] && !simpleChange['selectedNodes'].firstChange) {
      this.updateSelectedNodes();
    }
    
    //This will expand all top nodes (level==0) in the tree
    this.treeControl.dataNodes?.forEach((dataNode) => {
      if (dataNode.level === 0) {
        this.treeControl.expand(dataNode);
      }
    })

  }

  getLevel = (node: FlatNode) => node.level;

  isExpandable = (node: FlatNode) => node.expandable;

  getChildren = (node: Node): Node[] => node.children ?? [];

  hasChild = (_: number, _nodeData: FlatNode) => _nodeData.expandable;

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  transformer = (node: Node, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode =
      existingNode && existingNode.item === node.item
        ? existingNode
        : {} as FlatNode;
    flatNode.item = node.item;
    flatNode.level = level;
    flatNode.expandable = !!node.children && node.children.length > 0;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  };

  /** Whether all the descendants of the node are selected. */
  descendantsAllSelected(node: FlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    return descAllSelected;
  }

  /** Whether part of the descendants are selected */
  descendantsPartiallySelected(node: FlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child =>
      this.checklistSelection.isSelected(child)
    );
    return result && !this.descendantsAllSelected(node);
  }

  /** Toggle the to-do item selection. Select/deselect all the descendants node */
  toggleItemsSelected(node: FlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);

    // Force update for the parent
    descendants.every(child => this.checklistSelection.isSelected(child));
    this.checkAllParentsSelection(node);
    this.emitSelections();
  }

  /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
  toggleLeafItemSelected(node: FlatNode): void {
    this.checklistSelection.toggle(node);
    this.checkAllParentsSelection(node);
    this.emitSelections();
  }

  /* Checks all the parents when a leaf node is selected/unselected */
  checkAllParentsSelection(node: FlatNode): void {
    let parent: FlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /** Check root node checked state and change it accordingly */
  checkRootNodeSelection(node: FlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /* Get the parent node of a node */
  getParentNode(node: FlatNode): FlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  /**
   * Filters the viewable data to display only those options and children containing the @param filterText
   * @param filterText Text that should be searched for within the tree
   */
  filterChanged(filterText: string) {
    let filteredTreeData;
    if (filterText) {
      // Filter the tree
      function filter(array: any[], text: string) {
        const getChildren: (result: Node[], node: Node) => Node[] =
          (result: Node[], node: Node): Node[] => {
            if (node.item.toLowerCase().includes(text.toLowerCase())) {
              result.push(node);
              return result;
            }
            if (Array.isArray(node.children)) {
              const children = node.children.reduce(getChildren, []);
              if (children.length) result.push({ ...node, children });
            }
            return result;
          };

        return array.reduce(getChildren, []);
      }

      filteredTreeData = filter(this.nodeTree ?? [], filterText);
    } else {
      // Return the initial tree
      filteredTreeData = this.nodeTree;
    }
    // Reset viewable data
    this.dataSource.data = filteredTreeData;

    if (filterText) {
      this.treeControl.expandAll();
    } else {
      this.treeControl.collapseAll();
    }
  }

  /**
   * Builds a tree of selected nodes and emits them
   */
  emitSelections(): void {
    if (this.emitSelectionsImmediately) {
      const selectedNodes: Node[] = [];
      const allNodes: Node[] = Array.from(this.nodeTree);
      for (const rootNode of allNodes) {
        const selectedNode = this.getSelectedNodes(rootNode);
        if (selectedNode) {
          selectedNodes.push(selectedNode);
        }
      }

      this.nodesSelectedChange.emit(selectedNodes);
    }
  }

  /**
   * Recursively traverses the tree using the given node and selects the leaf that matches the given node
   * Logs an error if the given node can't be found in the tree
   * @param node Current node to check
   */
  setSelectedNodes(node: Node): void {
    const logPathNotFound = () => console.error('Could not select all nodes in the node tree (given node, node tree)', node, this.nodeTree);
    const selectNode = (node: Node) => {
      const flatNode = this.nestedNodeMap.get(node);
      if (flatNode && !this.checklistSelection.isSelected(flatNode)) {
        this.toggleItemsSelected(flatNode);
      }
    };

    const selectSubNodes =
      (node: Node, tree: Node): boolean => {
        let foundNodeAndChildren: boolean = false; // used to only log path not found once
        if (node.item === tree.item) {
          foundNodeAndChildren = true;
          if (node.children && node.children.length > 0
            && tree.children && tree.children.length > 0) {
            for (const n of node.children) {
              let foundN: boolean = false;
              for (const t of tree.children) {
                foundN = foundN || selectSubNodes(n, t);
              }
              foundNodeAndChildren = foundNodeAndChildren && foundN;
            }
          }
          else if (node.children && node.children.length > 0) {
            foundNodeAndChildren = false;
          }
          else if (tree.children && tree.children.length > 0) {
            for (const t of tree.children) {
              selectNode(t);
            }
            foundNodeAndChildren = true;
          }
          else {
            selectNode(tree);
            foundNodeAndChildren = true;
          }
        }
        return foundNodeAndChildren;
      };

    let found = false;
    for (const treeNode of this.nodeTree) {
      if (treeNode.item === node.item) {
        found = true && selectSubNodes(node, treeNode);
        break;
      }
    }

    if (!found) {
      logPathNotFound();
    }
  }

  /**
   * Resets the selected nodes and selects any in the selected nodes array
   */
  updateSelectedNodes(): void {
    this.emitSelectionsImmediately = false;

    for (const flatNode of this.treeControl.dataNodes) {
      if (this.checklistSelection.isSelected(flatNode)) {
        this.checklistSelection.deselect(flatNode);
      }
    }

    if (this.nodeTree?.length > 0 && this.selectedNodes?.length > 0) {
      for (const n of this.selectedNodes) {
        this.setSelectedNodes(n);
      }
    }

    this.emitSelectionsImmediately = true;
    this.emitSelections();
  }

  /**
   * Gets the node and any children that are selected
   * @param node current node to check
   * @returns a replicated node (from parameter) with all children selected
   */
  private getSelectedNodes(node: Node): Node | null {
    const flatNode = this.nestedNodeMap.get(node);
    if (flatNode && !flatNode.expandable && this.checklistSelection.isSelected(flatNode)) {
      return {
        item: node.item
      };
    }
    else if (node.children) {
      const selectedChildren: Node[] = [];
      for (const child of node.children) {
        const selectedChild = this.getSelectedNodes(child);
        if (selectedChild) {
          selectedChildren.push(selectedChild);
        }
      }

      if (selectedChildren.length > 0) {
        const newNode: Node = {
          item: node.item,
          children: selectedChildren
        };
        return newNode;
      }
    }

    return null;
  }

}

<div class="protocol-container">
    <div class="protocol-header" *ngIf="!hideTitle">
        <span>Protocol Materials</span>
    </div>
    <span *ngIf="!isKeyPersnl">
        <div *ngIf="(hybridRecord$ | async) as velos">
            <p>View the most recent attached protocol document <a href="{{plmUrl + velos.studyNumber}}"
                    target="_blank">here</a></p>
            <p>Please note this may not be the latest approved version from IRBe.</p>
        </div>
    </span>
    <br />
    <span *ngIf="!isKeyPersnl">
        Current Consent Forms, Assent Forms, and Eligibility Checklists:
    </span>
    <span *ngIf="isKeyPersnl">
        <div *ngIf="(hybridRecord$ | async) as velos">
            <p>View the most recent attached protocol document<a href="{{plmUrl + velos.studyNumber}}"
                    target="_blank">here</a></p>
            <p>Please note this may not be the latest approved version from IRBe.</p>
        </div>
        <br />
        Current Consent Forms
    </span>
    <br />
    <div class="form-dropdown">
        <mat-form-field [style.width.px]=260 appearance="fill">
            <mat-label *ngIf="constentDocuments?.length">Choose Consent Form</mat-label>
            <mat-label *ngIf="!constentDocuments?.length">No Consent Form Available</mat-label>
            <mat-select (valueChange)="selectedFile($event)">
                <mat-option *ngFor="let consent of constentDocuments" [value]="consent">
                    {{ consent.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <span class="prepare-consent" *ngIf="ptraxConsentLink">
            <a href="{{ ptraxConsentLink }}" target="_blank">Prepare Consent</a>
        </span>

        <br />

        <mat-form-field *ngIf="!isKeyPersnl" [style.width.px]="hasChecklists() ? 260: 298" appearance="fill">
            <mat-label *ngIf="hasChecklists()">Choose Eligibility Checklist</mat-label>
            <mat-label *ngIf="!hasChecklists()">No Eligibility Checklist Available</mat-label>
            <mat-select (valueChange)="selectedFile($event)">
                <mat-option *ngFor="let eligibility of eligibilityDocuments" [value]="eligibility">
                    {{ eligibility.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>
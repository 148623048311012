import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import sanitizeHtml from 'sanitize-html';

@Pipe({
  name: 'safeHtml'
})
export class SafeHtmlPipe implements PipeTransform {

  constructor(private sanitized: DomSanitizer) { }
  transform(value:string) {
    if (value) {
      const result = sanitizeHtml(value); 
      return this.sanitized.bypassSecurityTrustHtml(result);
    }
    return value;
  }
}

import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { OrgResponsibility } from 'src/app/common/models/org-responsibility';
import { DetailsService } from 'src/app/common/services/details.service';

@Component({
  selector: 'rpc-responsibilities',
  templateUrl: './more-info-responsibilities.component.html',
  styleUrls: ['./more-info-responsibilities.component.scss'],
})
export class MoreInfoResponsibilitiesComponent implements OnInit, OnDestroy {
  @Input()
  uNumber!: string;

  orgResponsibilities!: OrgResponsibility;
  subscriptionsList: Subscription[] = [];

  constructor(private detailsService: DetailsService) {}

  ngOnDestroy(): void {
    this.subscriptionsList.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  ngOnInit(): void {
    this.subscriptionsList.push(
    this.detailsService
      .getOrgResponsibilities(this.uNumber)
      .subscribe((res) => {
        this.orgResponsibilities = res;
      })
    );
  }


}
